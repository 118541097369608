/**
=========================================================
* Tongo.AI 
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import { useState, useEffect } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation, Outlet } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import Sidenav from 'tongoComponents/Sidenav';
import Configurator from 'tongoComponents/Configurator';

// Material Dashboard 2 React themes
import theme from 'assets/theme';

// Material Dashboard 2 React Dark Mode themes
import themeDark from 'assets/theme-dark';

// Material Dashboard 2 React routes
import initRoutes from 'routes';

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from 'context';

// Images
import brandWhite from 'assets/images/icon.png';
import brandDark from 'assets/images/icon.png';

// Route level protection
import localforage from 'localforage';
import UserAdmin from 'layouts/system-admin/userAdmin';
import UserAdminEdit from 'layouts/system-admin/userAdmin/edit';

import { Provider } from 'react-redux';
import {store} from './state/store';


//make a copy so we don't repeatedly change the core routes
const routes = [...initRoutes];

localforage.getItem('user').then((user) => {

  if (user?.roles?.includes('admin')) {
    routes.push({
      type: 'title',
      key: 'admin',
      title: 'Tongo Administration',
    });
    routes.push({
      type: 'collapse',
      name: 'User Administration',
      key: 'admin-user',
      icon: <Icon fontSize="small">admin_panel_settings</Icon>,
      component: <UserAdmin />,
      route: '/admin/users',
    });
    routes.push({
      type: 'route',
      key: 'admin-user-edit',
      component: <UserAdminEdit />,
      route: '/admin/users/:userId/edit',
    });
    // routes.push({ type: 'route', name: 'user admin', key: 'user-admin', route: '/admin/users', protected: false, component: <UserAdmin /> });
    // routes.push({ type: 'route', name: 'agency admin', key: 'agency-admin', route: '/admin/agencies', protected: false, component: <UserAdmin /> });
  }
});

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(null);

  // Open sidenav when mouse enter on mini sidenav


  
    

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const ProtectedRoute = ({ children }) => {
    localforage.getItem('isAuthenticated').then((isAuthenticated) => {
      if (isAuthenticated) return <Outlet />;
      else return <Outlet />;
      /*if (isAuthenticated) return <Outlet />;
      else return <Navigate to="/sign-in" replace />;*/
    });
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && !route.protected) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      } else if (route.route && route.protected) {
        return <Route exact path={route.route} element=<ProtectedRoute> {route.component} </ProtectedRoute> key={route.key} />;
      }

      return null;
    });

  return (
    <Provider store={store}>
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />


      {layout === 'dashboard' && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Tongo AI"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      {/* {layout === 'vr' && <Configurator />} */}

      <Routes>
        {getRoutes(routes)}
        {/* <Route path="*" element={<Navigate to="/sign-in" />} /> */}
      </Routes>
    </ThemeProvider>
    </Provider>
  );
}
