import { createSlice } from '@reduxjs/toolkit';

// Resume slice
const resumeSlice = createSlice({
    name: 'resume',
    initialState: {},
    reducers: {
        setResumeData: (state, action) => {
            return action.payload;
        },
    },
});
export const { setResumeData} = resumeSlice.actions;

export default resumeSlice.reducer;