/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
// react-router-dom components
import {Link} from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import bgImage from 'assets/images/bg-reset-cover.jpeg';

import {useState} from 'react';
import {resetPassword} from '../../../profile';

function ResetPassword() {
  const [errorSB, setErrorSB] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const closeError = () => setErrorSB(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const email = event.target.elements.email.value.trim().toLowerCase();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const formData = {email};

    if (!emailRegex.test(email)) {
      setErrorSB([{title: 'Password reset error', message: 'Enter a valid email address!'}]);
      return;
    } else {
      setLoading(true);
      resetPassword(formData)
        .then((result) => {
          setSuccess(true);
        })
        .catch((error) => {
          setErrorSB([{title: 'Password reset error', message: error.message}]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const renderError = (title, message) => (
    <MDSnackbar color="error" icon="error" title={title} content={message} dateTime="" open={Boolean(errorSB)} close={closeError} bgWhite />
  );

  return (
    <BasicLayout coverHeight="50vh" image={bgImage}>
      {errorSB.length ? renderError(errorSB[0].title, errorSB[0].message) : null}
      <MDLoading open={loading} text="Resetting password..." />;
      <Card>
        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="success" mx={2} mt={-3} py={2} mb={1} textAlign="center">
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an email containing instructions on how to reset your password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {success ? (
            <>
              <MDTypography variant="body2" color="text">
                An email containing instructions to reset your password has been sent to your email address.
                <br />
              </MDTypography>
              <MDTypography component={Link} to="/sign-in" variant="button" color="info" fontWeight="medium" textGradient>
                Sign In
              </MDTypography>
            </>
          ) : (
            <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
              <MDBox mb={4}>
                <MDInput name="email" type="email" label="Email" variant="standard" required={true} fullWidth />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton type="submit" variant="gradient" color="info" fullWidth>
                  reset
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?
                  <MDTypography component={Link} to="/sign-in" variant="button" color="info" fontWeight="medium" textGradient>
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
