/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState, useEffect} from 'react';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

import MiniSearch from '../searches/minisearch';


// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';
// import ReportsBarChart from 'tongoComponents/Charts/BarCharts/ReportsBarChart';
// import ReportsLineChart from 'tongoComponents/Charts/LineCharts/ReportsLineChart';

// Dashboard components

import {getProfile} from '../../profile';

// Data
// import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';



function Dashboard() {
  const [profile, setProfile] = useState({});
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);

  const [errorSB, setErrorSB] = useState([]);
  const closeError = () => setErrorSB([]);

  useEffect(() => {
    if (!fetched) {
      getProfile()
        .then((profile) => {
          setProfile(profile);
          setFetched(true);
        })
        .catch((error) => {
          if ([401, 404].includes(error.code)) {
            // Perform the redirect to the login screen
            //window.location.href = '/sign-in';
          } else {
            setErrorSB([{title: 'Error retrieving your account!', message: `Please review your info and try again! (${error})`}]);
            // Handle other errors
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={10} py={3}>
        <Grid container spacing={1}>
            {/* <Grid item xs={12} md={6} lg={8}>
              <Jobs />
            </Grid> */}
            <Grid item xs={12} md={6} lg={12}>
            <MiniSearch currentPage={"dashboard"} />
            </Grid>
          </Grid>
          {/*
        <Grid container spacing={3}>
          
        <Grid item xs={12} md={6} lg={3}>
          
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Jobs Found"
                count={281}
                percentage={{
                  color: 'success',
                  amount: '+55%',
                  label: 'than last week',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Applications"
                count="18"
                percentage={{
                  color: 'error',
                  amount: '-3%',
                  label: 'than last month',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Cover Letters"
                count="30"
                percentage={{
                  color: 'success',
                  amount: '+1%',
                  label: 'than last month',
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Responses"
                count="10"
                percentage={{
                  color: 'success',
                  amount: '',
                  label: 'Just updated',
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <MuiLink href="/search/new">
                  <MDButton size="large" color="info">
                    Add a job search
                  </MDButton>
                </MuiLink>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <MuiLink href="/short-list/new">
                  <MDButton size="large" color="info">
                    Add job to short list
                  </MDButton>
                </MuiLink>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={1}>
            
           <Grid item xs={12} md={6} lg={8}>
              <Jobs />
            </Grid> 
           <Grid item xs={12} md={6} lg={12}>
              <ScheduleOverview />
          </Grid>
          </Grid>
        </MDBox>*/}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
