/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// @mui material components
import Card from '@mui/material/Card';
import styles from '../../Wizard.module.css';
// Material Dashboard 2 React components
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TitleIcon from '@mui/icons-material/Title';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import Grid from '@mui/material/Grid';
// Material Dashboard 2 React example components
import TimelineItem from 'tongoComponents/Timeline/TimelineItem';
import TimelineList from 'tongoComponents/Timeline/TimelineList';
import ActionMenu from 'tongoComponents/ActionMenu';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

function CustomSection({ customSection, keyval, key, index, arr, handleEditElement }) {
  const module = 'custom';
  
  const tasks = [
    {
      label: 'Edit',
      icon: 'edit_note',
      action: (module, id, updatedElement) => {
        editElement(module, id, updatedElement);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Delete',
      icon: 'delete',
      action: (id) => {
        deleteElement(module, id);
      },
      confirmation: true,
      confirmationMessage: `Are you sure you want to delete this ${module}? This action cannot be undone.`,
    },
    {
      label: 'Move up',
      icon: 'move_up',
      action: (id) => {
        moveElementUp(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Move down',
      icon: 'move_down',
      action: (id) => {
        moveElementDown(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];

  const Addtask = [
    {
      label: 'Add new',
      icon: 'add',
      action: (module, id, newEducation) => {
        editElement(module, (id = 0), newEducation);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];

  const newCustomElement = {title: '', content: []}
  const newCustomContent = ''
 function addCustomElement() {
    const additionalCustomContent = [...customSection["content"], newCustomContent]
    //console.log("additionalCustomContent:", additionalCustomContent)
    handleFieldChange("content", additionalCustomContent, false, false)

  }
  const handleFieldChange = (field, value, isObject, nestedIndex) => {
    //console.log("*******************************************field,",field,"value:", value, "isObject:",isObject, "nestedIndex",nestedIndex  )
    if (nestedIndex||nestedIndex===0) {
      //console.log("*******************************************nested index is: ",nestedIndex)
      let contentArr = [...customSection["content"]]
      //console.log("field is: ",field)
      if (field.includes('.')){
        //console.log("nested field, includes . field is: ",field)
        
        let fieldArr = field.split('.')
        let superfield =fieldArr[0]
        let subfield = fieldArr[1]
        contentArr[nestedIndex][superfield][subfield] = value
        const updatedObject = { ...customSection, ["content"]: [...contentArr] }
        handleEditElement(module, index, updatedObject);
        }
      else{
      //console.log("not a nested field, no . field is: ",field)
      contentArr[nestedIndex] = value
      const updatedObject = { ...customSection, ["content"]: [...contentArr] }
      handleEditElement(module, index, updatedObject);
      }
    }
    else{ if (isObject) {

     //console.log("else if isObject:", isObject)
      const updatedObject = { ...customSection, [field]: { ...customSection[field], value } };
      //console.log("isObjectupdatedObject:", updatedObject)


      //console.log("customSection:", customSection)
      //console.log("[field]: value ", [field], " ", value)
      //console.log("module , index, value", module, " ", index, " ", value)

      handleEditElement(module, index, updatedObject);

    } else {
      //console.log("else if else:", isObject)
      const updatedObject = { ...customSection, [field]: value };

      //console.log("updatedObject:", updatedObject)
      //console.log("customSection:", customSection)
      //console.log("[field]: value ", [field], " ", value)
      //console.log("module , index, updatedObject", module, " ", index, " ", updatedObject)
      handleEditElement(module, index, updatedObject);
    }
    //console.log("customSection:", JSON.stringify(customSection))
  }
  };


  return (


    
      <MDBox key={index}>
        <Grid container spacing={6} mb={6}>
          {/*console.log('index: ', index)*/}
          <Grid marginX={"auto"} item xs={10}>

            {/*console.log('customSection: ', customSection)*/}
            <Grid item mt={-1} xs={8}>
 
            <Typography variant="h5" sx={{ fontWeight: 500 }}> {arr.length > 1 ? String(index + 1) + "." : ""}</Typography>
        
            <Stack px={3} mt={3} mb={5} direction="row" spacing={1}>
            <TitleIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></TitleIcon>
                    <MDInput
                      type="text"
                      label={"Section Title".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                      variant="filled"
                      fullWidth
                      name={"Section Title"}
                      aria-label={"Section Title".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                      value={customSection.title}
                      multiline
                      onChange={(e) => handleFieldChange("title", e.target.value, false,false)}
                    />
                    
                   </Stack>
                
                </Grid>

              {customSection.content.map((cus,index,arr)=>(<MDBox mt={2} ml={2} >
 

                <Grid item xs={12}>
                <Stack px={8} mt={3} mb={5} direction="row" spacing={1}>
            <ListAltIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></ListAltIcon>

            <MDInput

              variant="filled"
              sx={{
                backgroundColor: "#F3F6F9"
              }}
              fullWidth
    
              multiline

              type="text"
              name={"Section Content"}
              aria-label={"Section Content".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              value={cus}
 
              rows={3}
              onChange={(e) => handleFieldChange("content", e.target.value, false,index)}
            />

          </Stack>

                </Grid>
              </MDBox>
              
              ))}

<MDBox  ml={10} mt={1}><ListAltIcon pt={4} fontSize={"large"} sx={{ transform: "translate(-35%,35%)" }}></ListAltIcon> <IconButton  onClick={addCustomElement} sx={{ borderRadius: "30px"}} color="info" variant="outlined" size="large" mx={2} mt={3}><AddCircleOutlineIcon pt={4}  fontSize={"large"} ></AddCircleOutlineIcon></IconButton>  </MDBox>



          </Grid>
        </Grid>
      </MDBox>
  

  );
}

export default CustomSection;

CustomSection.defaultProps = {
  graduationMonth: '',
  content: '',
};

CustomSection.propTypes = {
  customSection: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,

      content: PropTypes.string,
    })
  ).isRequired,
  moveElementUp: PropTypes.func,
  moveElementDown: PropTypes.func,
  deleteElement: PropTypes.func,
  editElement: PropTypes.func,
};

const emptyCustomSection = {
  title: '',
  content: '',
};





