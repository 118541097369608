/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import {useParams, useNavigate} from 'react-router-dom';
import {updateUser, getUserDetails, deleteUser} from '../../../../sysadmin';

import React, {useState, useEffect} from 'react';
import Autocomplete from '@mui/material/Autocomplete';

import dayjs from 'dayjs';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
// import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import {Select} from '@mui/material';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import stateOptions from 'tongoComponents/Misc/states';
import Footer from 'tongoComponents/Footer';

function UserAdminEdit() {
  const queryParams = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: '',
    isVerified: false,
    firstName: '',
    lastName: '',
    city: '',
    state: '',
    created: dayjs(),
    lastSession: dayjs(),
    agencyId: '',
    agreeTerms: false,
    country: '',
    emailVerified: false,
    realm: '',
    resetKey: '',
    updated: '',
    avatar: '',
    middleName: '',
    salutation: '',
    title: '',
  });
  const salutationOptions = ['Mr.', 'Mrs.', 'Dr.', 'Prof'];
  const rolesOptions = ['admin', 'user', 'agencyAdmin'];

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const [retrieve, setRetrieve] = useState(false);
  const handleFormSubmit = async (event) => {
    if (loading) return;

    event.preventDefault();
    if (!roles || roles.length === 0) {
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: 'User role is a required field',
          message: 'Please add a role and try again!',
        },
      ]);
      return;
    }

    // const keywordsString = user.keywords.join(', ');

    try {
      setLoading(true);
      let successNavigate;

      const updatedUser = {
        ...user,
        roles: roles,
        email: user.email.toLowerCase(),
      };

      if (user.id) {
        await updateUser(updatedUser);
        successNavigate = `/admin/users`;
      }

      setMessageSB([
        {
          color: 'success',
          icon: 'check',
          title: `User was updated`,
          message: 'Redirecting to user admin...',
        },
      ]);

      setTimeout(() => {
        navigate(successNavigate);
      }, 1000);
    } catch (error) {
      if (error.status === 403) {
        await user?.signoffProfile();
        navigate('/sign-in');
      }
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: `Error updating user!`,
          message: 'Please review your info and try again!',
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  //handle form editing for controlled inputs
  const handleChange = (event) => {
    const {name, value} = event.target;
    setUser((user) => ({
      ...user,
      [name]: value,
    }));
  };

  const handleDateChange = (event) => {
    const {name, value} = event.target;
    const isoValue = value.toISOString();

    if (name === 'startDate' || name === 'endDate') {
      setUser((prevUser) => ({
        ...prevUser,
        startDate: name === 'startDate' ? isoValue : prevUser.startDate,
        endDate: name === 'endDate' ? isoValue : prevUser.endDate,
      }));
    }
  };

  useEffect(() => {
    if (!retrieve && queryParams?.userId)
      getUserDetails(queryParams.userId)
        .then((user) => {
          setUser(user);
          setRoles(user?.roles);
          setRetrieve(true);
        })
        .catch((error) => {
          // if (error.code === 401) {
          //   // Perform the redirect to the login screen
          //   window.location.href = '/sign-in';
          // } else {
          console.error(error);
          // Handle other errors
          // }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  const handleOnDelete = () => {
    setLoading(true);

    deleteUser(user.id)
      .then(() => {
        setMessageSB([
          {
            color: 'success',
            icon: 'check',
            title: `User was deleted`,
            message: 'Redirecting to user admin...',
          },
        ]);
        window.location.href = '/admin/users';
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: `Error deleting user!`,
            message: 'Please try again!',
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
        <MDLoading text="Retrieving user data..." open={loading} />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  User
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                  Edit User
                </MDTypography>
              </MDBox>
              <FormGroup>
                <MDBox pt={3} pb={3} px={3}>
                  <MDBox component="form" role="form" onSubmit={handleFormSubmit}>
                    <MDInput
                      type="email"
                      variant="standard"
                      margin="dense"
                      label="Email Address"
                      value={user?.email ?? ''}
                      name="email"
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                    {/* <FormControl variant="standard" sx={{mt: 2.1, minWidth: 100}}>
                      <InputLabel>Salutation</InputLabel>
                      <Select
                        type="text"
                        variant="standard"
                        margin="dense"
                        label="Salutation"
                        name="salutation"
                        onChange={handleChange}
                        value={user?.salutation ?? ''}
                        autoWidth
                      >
                        <MenuItem value="">None</MenuItem>
                        {salutationOptions.map((salutationOption) => (
                          <MenuItem key={salutationOption} value={salutationOption}>
                            {salutationOption}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <MDBox display="flex" justifyContent="space-between" alignItems="left" p={3}>
                      <MDBox display="flex" alignItems="left">
                        <MDTypography variant="caption" color="secondary">
                          &nbsp;&nbsp;Salutation
                          <br />
                        </MDTypography>
                        <Autocomplete
                          disableClearable
                          value={user?.salutation ?? ''}
                          options={salutationOptions}
                          namae="salutation"
                          onChange={(event, newValue) => {
                            handleChange;
                          }}
                          size="small"
                          sx={{width: '5rem'}}
                          renderInput={(params) => <MDInput {...params} variant="standard" />}
                        />
                      </MDBox>
                      <MDInput
                        type="text"
                        variant="standard"
                        margin="dense"
                        label="First Name"
                        value={user?.firstName ?? ''}
                        name="firstName"
                        onChange={handleChange}
                        required
                        // fullWidth
                      />
                      <MDInput
                        type="text"
                        variant="standard"
                        margin="dense"
                        label="Middle Name"
                        value={user?.middleName ?? ''}
                        name="middleName"
                        onChange={handleChange}
                        // fullWidth
                      />
                      <MDInput
                        type="text"
                        variant="standard"
                        margin="dense"
                        label="Last Name"
                        value={user?.lastName ?? ''}
                        name="lastName"
                        onChange={handleChange}
                        required
                        // fullWidth
                      />
                      <MDInput type="text" variant="standard" margin="dense" label="Title" value={user?.title ?? ''} name="title" onChange={handleChange} />
                      {/* <MDInput
                      type="text"
                      variant="standard"
                      margin="dense"
                      label="Mobile"
                      inputProps={{
                        pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
                        title: 'Please enter a valid telephone number (XXX-XXX-XXXX)',
                      }}
                      value={user?.telephone}
                      name="telephone"
                      onChange={handleChange}
                      fullWidth
                    /> */}
                    </MDBox>

                    <MDInput
                      type="url"
                      variant="standard"
                      margin="dense"
                      label="Agency"
                      value={user?.agencyId ?? ''}
                      name="agencyId"
                      onChange={handleChange}
                      fullWidth
                    />
                    {/* <Select margin="none" label="State" value={user?.state} name="state" onChange={handleChange} fullWidth>
                      {user?.state ??
                        stateOptions.map((stateOption) => (
                          <MenuItem key={stateOption.abbreviation} value={stateOption.abbreviation}>
                            {stateOption.name}
                          </MenuItem>
                        ))}
                    </Select> */}
                    <MDInput type="text" variant="standard" margin="dense" label="City" value={user?.city ?? ''} name="city" onChange={handleChange} />
                    <MDInput type="text" variant="standard" margin="dense" label="State" value={user?.state ?? ''} name="state" onChange={handleChange} />
                    <MDBox mt={4} width="100%" display="flex" flexDirection="column">
                      <Autocomplete
                        multiple
                        id="tags-standard"
                        size="medium"
                        options={rolesOptions}
                        value={roles ?? []} // Set the defaultValue to the array of keywords
                        renderInput={(params) => <MDInput {...params} variant="standard" label="Roles" placeholder="Roles" fullWidth />}
                        limitTags={3}
                        freeSolo
                        required
                        onChange={(event, newValue) => setRoles(newValue)}
                      />
                    </MDBox>
                    <MDBox mt={4} mb={0} display="flex" justifyContent="flex-start">
                      <MDButton onClick={() => navigate(-1)} variant="gradient" color="info" mr={2}>
                        Cancel
                      </MDButton>
                      <MDButton type="submit" variant="gradient" color="info" disabled={loading} mr={8}>
                        Update user
                      </MDButton>
                      <MDButton variant="gradient" color="error" disabled={loading} onClick={handleOnDelete}>
                        Delete user
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </FormGroup>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserAdminEdit;
