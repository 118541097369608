/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState} from 'react';
import MDBox from 'components/MDBox';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';

const IndentedList = ({resumeData, level = 0, copiedPath}) => {
  const indentation = '  '.repeat(level); // Two spaces per level of nesting
  const [copiedText, setCopiedText] = useState(null);
  const [hoveredKey, setHoveredKey] = useState(null);
  const [icon, setIcon] = useState('copy');

  const handleCopyClick = (text) => {
    setIcon('check');
    navigator.clipboard.writeText(text).then(() => {
      console.log(text);
      setCopiedText(text);
      setTimeout(() => {
        setCopiedText(null);
        setIcon('copy');
      }, 1000); // Clear the "Copied" message after 1 second
    });
  };

  return (
    <MDBox component="ul">
      {resumeData &&
        Object.entries(resumeData).map(([key, value], index) => (
          <MDBox
            component="li"
            key={index}
            display="flex"
            flexDirection="column"
            mt={1}
            sx={{
              borderLeft: '1px dashed grey',
              backgroundColor: hoveredKey === key ? 'light.main' : 'transparent',
              '&:hover': {
                backgroundColor: hoveredKey === key ? 'light.main' : 'transparent',
              },
            }}
            // onMouseEnter={() => setHoveredKey(key)}
            // onMouseLeave={() => setHoveredKey(null)}
          >
            {typeof value === 'object' && value !== null ? (
              <>
                <MDBox
                  display="flex"
                  alignItems="center"
                  onMouseEnter={() => setHoveredKey(key)}
                  onMouseLeave={() => setHoveredKey(null)}
                  sx={{
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'light.main',
                      // opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                >
                  <span>{`${indentation}${key}`}</span>
                  {hoveredKey === key && (
                    <Tooltip title={copiedText === `${JSON.stringify(value, null, 2)}` ? 'Copied!' : 'Click to copy this text'} placement="top-start">
                      <Icon onClick={() => handleCopyClick(`${JSON.stringify(value, null, 2)}`)} size="small" color="caption">
                        {icon}
                      </Icon>
                    </Tooltip>
                  )}
                  {/* {copiedText === `${JSON.stringify(value, null, 2)}` && <span> Copied!</span>} */}
                </MDBox>
                <MDBox ml={2}>
                  <IndentedList resumeData={value} level={level + 1} copiedPath={`${copiedPath}.${key}`} />
                </MDBox>
              </>
            ) : (
              <MDBox
                display="flex"
                alignItems="center"
                onMouseEnter={() => setHoveredKey(key)}
                onMouseLeave={() => setHoveredKey(null)}
                sx={{
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'light.main',
                    // opacity: [0.9, 0.8, 0.7],
                  },
                }}
              >
                <span>{`${indentation}${key}: ${value}`}</span>
                {hoveredKey === key && (
                  <Tooltip title={copiedText === `${JSON.stringify(value, null, 2)}` ? 'Copied!' : 'Click to copy this text'} placement="top-start">
                    <Icon onClick={() => handleCopyClick(`${copiedPath}.${key}: ${value}`)} size="small" color="caption">
                      {icon}
                    </Icon>
                  </Tooltip>
                )}
              </MDBox>
            )}
          </MDBox>
        ))}
    </MDBox>
  );
};

IndentedList.propTypes = {
  resumeData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  level: PropTypes.number,
  copiedPath: PropTypes.string,
};

export default IndentedList;
