import localforage from 'localforage';

export async function search(path, method, body = null) {
  console.log("path, method, body: ",path, method, body )
  try {
    const options = await getOptions(method, body);
    const apiUrl = `${process.env.REACT_APP_SEARCH_MICROSERVICE}/${path}`;

    if (body !== null)
      if (body instanceof FormData) {
        // Remove the Content-Type header for FormData


        console.log(body)
        console.log("BODY")
        console.log("form data")
        const headers = new Headers(options.headers);
        headers.delete('Content-Type');
        options.headers = headers; // Set the updated headers
        console.log(headers)
        console.log("headers")
        console.log("form data")
      } else {
        // If not FormData, stringify the body as JSON
        

        console.log(body)
        console.log("body")
        console.log("not form data")
        options.body = JSON.stringify(body);
      }

    const response = await fetch(apiUrl, options);
      console.log("response ",response)
    switch (response.status) {
      case 200:
        console.log("200 okay")
      case 201:
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          // Response is JSON, parse it
          return await response.json();
        } else {
          // Response is not JSON, treat it as text
          return await response.text();
        }
      case 204:
        return true; // 204 returns no data
      case 401:
        handleUnauthorized(); // Redirect user
        break;
      default:
        console.log("error?")
        console.log("response")
        console.log(response)
        console.log("response.status")
        console.log(response.status)
        console.log(" response.statusText")
        console.log( response.statusText)
        throw new CustomError(response.status, response.statusText);
    }
  } catch (error) {
    handleError(error);
  }
}

export async function fetcher(path, method, body = null) {
  console.log("in fetcher path, method, body: ",path, method, body )
  try {
    const options = await getOptions(method, body);
    const apiUrl = `${process.env.REACT_APP_API_SERVER_URL}/${path}`;

    if (body !== null)
      if (body instanceof FormData) {
        // Remove the Content-Type header for FormData


        console.log(body)
        console.log("BODY")
        console.log("form data")
        const headers = new Headers(options.headers);
        headers.delete('Content-Type');
        options.headers = headers; // Set the updated headers
        console.log(headers)
        console.log("headers")
        console.log("form data")
      } else {
        // If not FormData, stringify the body as JSON
        

        console.log(body)
        console.log("body")
        console.log("not form data")
        options.body = JSON.stringify(body);
      }

    const response = await fetch(apiUrl, options);
 
    switch (response.status) {
      case 200:
        console.log("200 okay")
      case 201:
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          // Response is JSON, parse it
          return await response.json();
        } else {
          // Response is not JSON, treat it as text
          return await response.text();
        }
      case 204:
        return true; // 204 returns no data
      case 401:
        handleUnauthorized(); // Redirect user
        break;
      default:
        console.log("error?")
        console.log("response")
        console.log(response)
        console.log("response.status")
        console.log(response.status)
        console.log(" response.statusText")
        console.log( response.statusText)
        throw new CustomError(response.status, response.statusText);
    }
  } catch (error) {
    handleError(error);
  }
}

// Helper function to get the user ID
export async function getUserId() {
  const user = await localforage.getItem('user');
  if (!user?.id) {
    const error = new Error('No user ID provided');
    error.code = 401;
    throw error;
  }
  return user.id;
}

// Helper function to get the user resume
export async function getUserResume() {
  const user = await localforage.getItem('user');
  if (!user?.resumeParsed || user?.resumeParsed?.length === 0) {
    const error = new Error('No user resume');
    error.code = 402;
    throw error;
  }
  return user.resumeParsed;
}

async function getOptions(method, body = null) {
  const token = await localforage.getItem('token');

  let options = {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
    },
  };

  if (body) {
    removeNullFields(body);
    options.body = body;
  }

  return options;
}

function removeNullFields(obj) {
  for (const key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
}

function handleUnauthorized() {
  window.location.href = '/sign-in';
  throw new Error('Unauthorized');
}

function handleError(error) {
  // Handle network errors or API server offline here
  if (error.message.includes('Failed to fetch')) {
    // Handle API server offline situation
    window.location.href = '/offline';
  } else {
    // Handle other types of errors
    throw error; // Rethrow a more generic error for catch block
  }
}

class CustomError extends Error {
  constructor(code, message) {
    super(message); // Call the parent constructor (Error)
    this.name = 'CustomError'; // Set the name of the custom error
    this.code = code; // Set the custom error code
    this.message = message; // Set the custom error message
  }
}
