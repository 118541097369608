/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-routers components
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {updateProfile} from '../../../profile';

// prop-types is library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@mui/material/InputLabel';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';

function ProfilesList({title, description, edit, profiles, shadow}) {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;
  const [open, setOpen] = useState(false);
  const [accIndex, setAccIndex] = useState();
  const [accommodations, setAccommodations] = useState(profiles);
  const [newAccommodation, setNewAccommodation] = useState();
  const [loading, setLoading] = useState(false);
  const [save, setSave] = useState(false);

  const handleChange = (e, index) => {
    const {name, value} = e.target;

    if (index !== '') {
      const updatedAccommodations = [...accommodations];

      if (updatedAccommodations[index]) {
        updatedAccommodations[index] = {
          ...updatedAccommodations[index],
          [name]: value,
        };
      }

      setAccommodations(updatedAccommodations);
    } else {
      setNewAccommodation({...newAccommodation, [name]: value});
    }
  };

  const handleOpen = (index) => {
    setOpen(true);
    setAccIndex(index);
  };

  const handleClose = (e, r) => {
    if (r && r == 'backdropClick') return;
    setOpen(false);
  };

  const handleSave = (e, index) => {
    if (accommodations.length === 0 || index === '') {
      // Set default action for new accommodation
      const defaultAction = {type: 'internal', label: 'edit'};

      // Add default action to the new accommodation
      const newAccommodationWithAction = {
        ...newAccommodation,
        action: newAccommodation.action || defaultAction,
      };

      // If accommodations is empty or index is empty, add the new accommodation to the array
      setAccommodations((accommodations) => [...accommodations, newAccommodationWithAction]);
    }
    setSave(true);
  };

  const handleDelete = (index) => {
    setAccommodations((accommodations) => accommodations.filter((_, i) => i !== index));
    setSave(true);
  };

  useEffect(() => {
    if (save) {
      setLoading(true);
      //handle new item
      updateProfile({accommodations: Object.values(accommodations)})
        .then((result) => {
          //reload
          window.location.reload(false);
        })
        .catch((error) => {
          // setErrorSB([{ title: 'Error saving your profile', message: error }]);
        })
        .finally(() => {
          setSave(false);
          setLoading(false);
          handleClose();
        });
    }
  }, [save]);

  const renderDialog = (index) => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Accomodations and Special Mentions</DialogTitle>
        <DialogContent>
          <MDInput
            type="text"
            variant="standard"
            margin="dense"
            label="Name"
            value={accommodations[index]?.name}
            name="name"
            id={'${index}'}
            onChange={(e) => handleChange(e, index)}
            autoFocus
            fullWidth
          />
          <MDInput
            type="text"
            variant="standard"
            margin="dense"
            label="Description"
            multiline
            value={accommodations[index]?.description}
            id={'${index}'}
            name="description"
            onChange={(e) => handleChange(e, index)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" onClick={handleClose} fullWidth>
              cancel
            </MDButton>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="info" onClick={(e) => handleSave(e, index)} fullWidth>
              {index === '' ? 'add' : 'save'}
            </MDButton>
          </MDBox>
        </DialogActions>
      </Dialog>
    );
  };
/*
  const renderProfiles = profiles.map(({image, name, description, action}, index) => (
   
    <MDBox key={index} component="li" display="flex" alignItems="center" py={1} mb={1}>
       {console.log("image: ", image, "name: ",name, "description: ", description, "action: ",action)}
      {image && (
        <MDBox mr={2}>
          <MDAvatar src={image} alt="image" shadow="md" />
        </MDBox>
      )}
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption" color="text">
          {description}
        </MDTypography>
      </MDBox>
      {action.type === 'internal' ? (
        action.label === 'edit' ? (
          <MDBox ml="auto">
            <MDTypography onClick={() => handleOpen(index)} variant="body2" color="text">
              <Tooltip title="Edit" placement="top">
                <Icon>edit</Icon>
              </Tooltip>
            </MDTypography>
            <MDTypography onClick={() => handleDelete(index)} variant="body2" color="text">
              <Tooltip title="Delete" placement="top">
                <Icon>delete</Icon>
              </Tooltip>
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox ml="auto">
            <MDButton component={Link} to={action.route} variant="text" color="info">
              {action.label}
            </MDButton>
          </MDBox>
        )
      ) : (
        <MDBox ml="auto">
          <MDButton component="a" href={action.route} target="_blank" rel="noreferrer" variant="text" color={action.color}>
            {action.label}
          </MDButton>
        </MDBox>
      )}
    </MDBox>
  ));
*/
  return (
    <Card sx={{height: '100%', boxShadow: !shadow && 'none'}}>
      <MDLoading text="Saving..." open={loading} onClose={() => {}} />
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>

        {/* <MDTypography component={Link} to={edit.route} variant="body2" color="secondary"> */}
        <MDTypography onClick={() => handleOpen('', '')} variant="body2" color="secondary">
          <Tooltip title={edit.tooltip} placement="top">
            <Icon>add</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox pl={2} pr={2}>
        <MDBox display="flex" py={0} pr={0}>
          <MDTypography lineHeight={1.5} variant="caption" color="info">
            {description}
          </MDTypography>
        </MDBox>
        <Divider orientation="horizontal" sx={{ml: -2, mr: 1}} />
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {/*renderProfiles*/}
        </MDBox>
      </MDBox>
      {open ? renderDialog(accIndex) : null}
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  edit: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
