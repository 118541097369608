/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

const appBar = {
  defaultProps: {
    color: 'transparent',
  },

  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
  },
};

export default appBar;
