/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

const link = {
  defaultProps: {
    underline: 'none',
    color: 'inherit',
  },
};

export default link;
