import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import {useNavigate} from 'react-router-dom';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import MDButton from 'components/MDButton';
import { makeStyles } from "@mui/material/styles";
import Divider from '@mui/material/Divider';
import { useState,useEffect } from 'react';
import Stack from "@mui/material/Stack";
import styles from './JobList.module.css';


function InteractiveList({ list,onSwipePage,handleSelectJobByIndex,selectedJobId,   reloadTrigger, accessor, primaryText, secondaryText }) {
    const [dense, setDense] = useState(false);
    const [secondary, setSecondary] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState(null);
    /*const [selected, setSelected] = useState(null);*/

    const navigate = useNavigate();
    useEffect(() => {
        
        console.log("selectedJobIdselectedJobIdselectedJobIdselectedJobIdselectedJobId",selectedJobId)
        if(!onSwipePage){
            let selectedIdx=0
            if(selectedJobId){
                for(let i =0;i<list.length;i++){
                    if(String(list[i].id)==String(selectedJobId)){
                        selectedIdx=i
                    }
                }
           
            }
            setSelectedIndex(selectedIdx);   
       }


   
    })


  
    const handleListItemClick = (event, index) => {
        if(onSwipePage){
            
            navigate(`/short-list/${list[index].id}`);
   
        }
            else{
        setSelectedIndex(index);
        handleSelectJobByIndex(index)
        }
    };




    const style = {
        py: 0,
        width: '100%',
        minWidth: 500,
        borderRadius: 2,
        border:  'none',//'1px solid',
        borderColor: 'divider',
        listStyleType: 'none',
        pt: 0,
        pb: 0
    };
    const textStyles = {
        primaryTypographyProps: {
            fontSize: 14
        }

    };
    const ButtonLink = ({ id }) => {
        return <Link to={{
            pathname: `/short-list/${id}`
            // your data array of objects}}><button>Apply Now!</button></Link>;
        }}><MDButton size="small" color="info"   >Apply now!</MDButton></Link>
    }

    const listItems=[];
    for (let idx = list.length - 1; idx >= 0; idx--) {
        let value= list[idx];
             
        listItems.push(<div>
        
           
            <Divider component="li" />
            <ListItemButton selected={selectedIndex === idx} onClick={(event) => handleListItemClick(event, idx)}>
                <Grid container spacing={2}>
                    <Grid item ml={2} mt={0} xs={7} md={7}>
                        <ListItemText
                            primaryTypographyProps={{
                                fontSize: 14
                            }}
                            secondaryTypographyProps={{
                                fontSize: 12
                            }}
                            
                            key={`${String(value.jobTitle).replace(" ","")}+${String(value.company).replace(" ","")}+${idx}`}
                            primary={value.jobTitle}
                            secondary={value.companyName ? value.companyName : " -"}
                        />
                    </Grid>
             
                    {onSwipePage?
                    <Grid item ml={0} mt={2} xs={4} md={4}>    
                <Grid container justifyContent={"flex-end"} spacing={0}>
                <Grid item mt={-0.5}> 
                     
                            </Grid>
                            {reloadTrigger?"" :""}
                            <Grid item  pl={2} ml={2} mt={0}> 
                        <ListItemIcon  >

                            <ArrowForwardIosIcon fontSize="small" />


                        </ListItemIcon>
                    
                    </Grid>
                    </Grid>
                    </Grid>
                        :       <Grid item ml={0} mt={0} xs={2} md={1}>
                                       
                        {onSwipePage?"apply!":""}
                            <ListItemIcon >

                                <ArrowForwardIosIcon fontSize="small" />


                            </ListItemIcon>
                        </Grid>}

                </Grid>
            </ListItemButton>

        </div>
    )}
    return (
       
            <Box sx={{ flexGrow: 1, maxWidth: 752 }}>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>

                        <List dense="false" pt={0} sx={style}>
                            <Typography sx={{ ml: 3, mt: 0, mb: 2 }} variant="h4" component="div">
                               Apply
                            </Typography>
                            <MuiLink href="/short-list/new" rel="noreferrer">

                                <Box mx={2} mt={3} py={3} px={2} pb={0} pt={0}>
                                    <Divider component="li"/>

                                    <ListItemButton

                                        onClick={(event) => handleListItemClick(event, 0)}
                                    >        <Stack direction="row" alignItems="right" gap={2}>

                                            <ListItemIcon >

                                                <AddIcon fontSize="large" />


                                            </ListItemIcon>

                                            <ListItemText
                                                primaryTypographyProps={{
                                                    fontSize: 19
                                                }}
                                                primary="Add a Job"

                                            />

                                        </Stack>
                                    </ListItemButton>
                              


                                </Box>
                            </MuiLink>
                            <Box  open={true} className={styles.carouselwrapper}   sx={{ overflowY: "scroll", maxHeight: "800px" }} >
                            {listItems}
        
                        
                            </Box>
                        </List>

                    </Grid>
  
                </Grid>
            </Box>
    
    );
}

export default InteractiveList