/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from 'layouts/dashboard';

import Jobs from 'layouts/shortList';
import EditJob from 'layouts/shortList/edit';
import ViewJob from 'layouts/shortList/view';
import ApplyJob from 'layouts/shortList/apply';

import Searches from 'layouts/searches';
import EditJobSearch from 'layouts/searches/edit';

import Applications from 'layouts/applications';

//to delete
import UserAdmin from 'layouts/system-admin/userAdmin';
import UserAdminEdit from 'layouts/system-admin/userAdmin/edit';

// import Billing from 'layouts/billing';
import Account from 'layouts/account';
import Resume from 'layouts/resume';
import ResumeWizard from 'layouts/wizard';
import SignIn from 'layouts/authentication/sign-in';
import SignUp from 'layouts/authentication/sign-up';
import SignOff from 'layouts/authentication/sign-off';
import Offline from 'layouts/authentication/offline';
import ResetPassword from 'layouts/authentication/reset-password';
import ResetPasswordFinish from 'layouts/authentication/reset-password-finish';
import VerifyAccount from 'layouts/authentication/verify';

// @mui icons
import Icon from '@mui/material/Icon';

const routes = [
  {
    type: 'route',
    name: 'Signin',
    key: 'main',
    icon: <Icon fontSize="small"> dashboard </Icon>,
    route: '/',
    protected: false,
    component: <SignIn />,
  },
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    icon: <Icon fontSize="small"> dashboard </Icon>,
    route: '/dashboard',
    protected: false,
    component: <Dashboard />,
  },
  {
    type: 'collapse',
    name: 'Find a Job',
    key: 'search',
    icon: <Icon fontSize="small"> table_view </Icon>,
    route: '/search',
    protected: false,
    component: <Searches />,
  },
  {
    type: 'route',
    name: 'Create a Job Search',
    key: 'search',
    route: '/search/new',
    protected: false,
    component: <EditJobSearch />,
  },
  {
    type: 'route',
    title: 'Edit Job Search',
    icon: <Icon fontSize="small"> table_view </Icon>,
    key: 'search',
    protected: false,
    route: '/search/:searchId/edit',
    component: <EditJobSearch />,
  },
  {
    type: 'collapse',
    name: 'Apply to Jobs',
    route: '/short-list',
    key: 'short-list',
    icon: <Icon fontSize="small"> table_view </Icon>,
    protected: false,
    component: <Jobs />,
  },
  {
    type: 'route',
    title: 'Apply to a Job',
    icon: <Icon fontSize="small"> table_view </Icon>,
    key: 'short-list',
    protected: false,
    route: '/short-list/new',
    component: <EditJob />,
  },
  {
    type: 'route',
    title: 'Edit Job',
    icon: <Icon fontSize="small"> table_view </Icon>,
    key: 'short-list',
    protected: false,
    route: '/short-list/:jobId/edit',
    component: <EditJob />,
  },
  {
    type: 'route',
    title: 'Job Application',
    icon: <Icon fontSize="small"> table_view </Icon>,
    key: 'short-list',
    protected: false,
    route: '/short-list/:jobId/apply',
    component: <ApplyJob />,
  },
  {
    type: 'route',
    name: 'Application View',
    icon: <Icon fontSize="small"> table_view </Icon>,
    key: 'short-list',
    protected: false,
    route: '/short-list/:jobId',

    component: <Jobs />,
  },
  {
    type: 'collapse',
    name: 'Track Applications',
    route: '/applications',
    key: 'applications',
    icon: <Icon fontSize="small"> table_view </Icon>,
    protected: false,
    component: <Applications />,
  },
  // {
  //   type: 'route',
  //   name: 'Application Generate Cover Letter',
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   key: 'gen-coverletter',
  //   protected: false,
  //   route: '/applications/:jobId/generateCoverLetter',
  //   component: <Billing />,
  // },
  // {
  //   type: 'collapse',
  //   name: 'Calendar',
  //   key: 'billing2',
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: '/billing',
  //   protected: false,
  //   component: <Billing />,
  // },
  {
    type: 'divider',
    key: 'divider-1',
  },
  {
    type: 'title',
    key: 'title',
    title: 'Profile',
  },

  // {
  //   type: 'collapse',
  //   name: 'My Profile',
  //   key: 'profile',
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: '/profile',
  //   protected: false,
  //   component: <Profile />,
  // },
  {
    type: 'collapse',
    name: 'My Account',
    key: 'account',
    icon: <Icon fontSize="small"> manage_accounts </Icon>,
    route: '/account',
    protected: false,
    component: <Account />,
  },
  {
    type: 'route',
    name: 'Résumé Wizaard',
    key: 'resume-wizard',
    icon: <Icon fontSize="small"> person</Icon>,
    route: '/resume/wizard',
    protected: false,
    component: <ResumeWizard />,
  },
  {
    type: 'collapse',
    name: 'My Résumé',
    key: 'resume',
    icon: <Icon fontSize="small"> person</Icon>,
    route: '/resume',
    protected: false,
    component: <Resume />,
  },

  {
    type: 'divider',
    key: 'divider-2',
  },
  {
    type: 'collapse',
    name: 'Sign Off',
    key: 'sign-off',
    icon: <Icon fontSize="small"> logout </Icon>,
    route: '/sign-off',
    component: <SignOff />,
  },
  {
    type: 'route',
    name: 'Sign In',
    key: 'sign-in',
    icon: <Icon fontSize="small"> login </Icon>,
    route: '/sign-in',
    component: <SignIn />,
  },
  {
    type: 'route',
    name: 'Sign Up',
    key: 'sign-up',
    route: '/sign-up',
    component: <SignUp />,
  },
  {
    type: 'route',
    name: 'Reset Password',
    key: 'reset-password',
    route: '/reset-password',
    component: <ResetPassword />,
  },
  {
    type: 'route',
    name: 'Verify Account',
    key: 'verify-account',
    route: '/verify',
    component: <VerifyAccount />,
  },
  {
    type: 'route',
    name: 'Complete Password Reset',
    key: 'reset-password-finish',
    route: '/reset-password-finish',
    component: <ResetPasswordFinish />,
  },
  {
    type: 'route',
    name: 'Offline',
    key: 'offline',
    route: '/offline',
    component: <Offline />,
  },
  //delete below
  {
    type: 'route',
    key: 'admin-user',
    component: <UserAdmin />,
    route: '/admin/users',
  },
  {
    type: 'route',
    key: 'admin-user-edit',
    component: <UserAdminEdit />,
    route: '/admin/users/:userId/edit',
  },
];

export default routes;
