/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import PropTypes from 'prop-types'; // Import PropTypes
import Box, { BoxProps } from '@mui/material/Box';
import React, {useState, useEffect} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mui/material/Icon';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Material Dashboard 2 React components
import MDSnackbar from 'components/MDSnackbar';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';
import { v4 as uuid } from 'uuid';

// react-router-dom components
import xtype from 'xtypejs';
import RenderFields from './renderFields';
function ActionMenu({id, tasks, formObject, onActionCompleted}) {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [task, setTask] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [editObject, setEditObject] = useState({});
  const [messageSB, setMessageSB] = useState([]);

  const closeMessage = () => setMessageSB([]);

  const preventClickMessage = (event) => {
    event.cancelBubble = true;
    event.stopPropagation();
    event.preventDefault();
  };

  const handleClick = (event) => {
    preventClickMessage(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditObjectChange = (newObject) => {
    // Update the editedObject when the user edits a field
    console.log("set edit object newObject",newObject )
    console.log("set edit object editObject", editObject)
    setEditObject(newObject);
    console.log("done ", JSON.stringify(editObject))
  };

  const openConfirmationDialog = (selectedTask) => {
    setTask(selectedTask);
    setConfirmationMessage(selectedTask.confirmationMessage);
    setIsConfirmationDialogOpen(true);
    handleClose(); // Close the menu when opening the confirmation dialog
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const openEditDialog = (selectedTask) => {
    setTask(selectedTask);
    setEditObject(formObject.obj);
    setIsEditDialogOpen(true);
    handleClose(); // Close the menu when opening the confirmation dialog
  };

  const closeEditDialog = (e, r) => {
    if (r && r == 'backdropClick') return;
    setIsEditDialogOpen(false);
  };

  const handleAction = (selectedTask) => {
    if (selectedTask)
      if (selectedTask.confirmation) {
        //check for confirmation dialog option
        openConfirmationDialog(selectedTask);
      } else if (['edit', 'add', 'add new', 'new'].includes(selectedTask.label.toLowerCase())) {
        // When "Edit" is selected, set the edited text
        openEditDialog(selectedTask);
      } else {
        performAction(selectedTask);
      }
  };

  const confirmAction = () => {
    if (task) {
      performAction(task);
    }
  };

  const performAction = async (selectedTask) => {
    try {
      const actionPromises = [];
        console.log("PERFFORMING ACTION!!!!!!selectedTask",selectedTask)
      if (['edit', 'add', 'add new', 'new'].includes(selectedTask.label.toLowerCase())) {
        actionPromises.push(selectedTask.action(formObject.title, id, editObject));
      } else {
        actionPromises.push(selectedTask.action(id));
      }
      // Notify the parent component that an action is completed
      if (typeof onActionCompleted === 'function') actionPromises.push(onActionCompleted());

      // Wait for all the action promises to complete
      await Promise.all(actionPromises);

      setMessageSB([
        {
          color: 'success',
          icon: 'check',
          title: `${selectedTask.label} succeeded!`,
          message: `${selectedTask.label} completed successfully`,
        },
      ]);
    } catch (e) {
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: `${selectedTask.label} failed!`,
          message: `${selectedTask.label} failed. Please review your info and try again!`,
        },
      ]);
    } finally {
      closeConfirmationDialog();
      closeEditDialog();
      handleClose();
    }
  };

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );

  //render a menu if more than 1 action, otherwise a simple button tied to the action
  const renderMenuOrButton = () => {
    if (tasks){
    return tasks.length === 1 ? (
      <MDButton size="small" variant="gradient" color="info" onClick={() => handleAction(tasks[0])}>
        <Icon sx={{fontWeight: 'bold'}}>add</Icon>
        &nbsp;{`${tasks[0].label} ${formObject.title}`}
      </MDButton>
    ) : (
      <>
        <MDBox color="text" px={2}>
          <Icon sx={{cursor: 'pointer', fontWeight: 'bold'}} fontSize="small" onClick={(e) => handleClick(e)}>
            more_vert
          </Icon>
        </MDBox>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {tasks.map((a, index) => (
            <MenuItem key={index} onClick={() => handleAction(a)}>
              <Icon>{a.icon}</Icon>&nbsp; {a.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }else{
    return ("")
  }
  };

  return (
    <>
      {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
      {renderMenuOrButton()}
      <Dialog open={isConfirmationDialogOpen} onClose={closeConfirmationDialog}>
        <DialogTitle>Confirm action</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmationMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="success"
            onClick={(e) => {
              preventClickMessage(e);
              closeConfirmationDialog();
            }}
            autoFocus
          >
            Cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="error"
            onClick={(e) => {
              preventClickMessage(e);
              confirmAction();
            }}
          >
            Confirm
          </MDButton>
        </DialogActions>
      </Dialog>
      <Dialog open={isEditDialogOpen} onClose={closeEditDialog} fullWidth={true}>
        <DialogTitle>Edit {formObject.title}</DialogTitle>
        <DialogContent>
          <MDBox pt={0} pb={3} px={3}>
            <MDTypography variant="button" color="text" fontWeight="light">
              To remove an item in a list, delete the text.
            </MDTypography>
            
            <RenderFields object={editObject} onChange={handleEditObjectChange} />
           
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="success"
            onClick={(e) => {
              preventClickMessage(e);
              closeEditDialog();
            }}
            autoFocus
          >
            Cancel
          </MDButton>
          <MDButton
            variant="gradient"
            color="info"
            onClick={(e) => {
              preventClickMessage(e);
              confirmAction();
            }}
          >
            Save
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );

}

ActionMenu.defaultProps = {
  formObject: {},
};

ActionMenu.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      confirmation: PropTypes.bool,
      confirmationMessage: PropTypes.string,
    })
  ).isRequired,
  onActionCompleted: PropTypes.func.isRequired,
  formObject: PropTypes.object,
};

export default ActionMenu;
