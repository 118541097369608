// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/icon?family=Material+Icons);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@60,400,0,0);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wizard_labels__KBrw4{
    font-size:1rem !important;
    color: #344767;
}
.Wizard_smallLabels__fYWiN{
    font-size:.9rem !important;
    color: #344767;
}


:root {
    --up: 2;
    --card-margin: 12px;
    --card-shadow: rgba(46, 49, 52, .4);
    --color-white: #fff;
    --color-lightgrey: #ccc;
    --color-ui: #999;
    --color-ui-hover: #e70808;
    --cardHeight: calc(96vh/var(--up));
    --carouselHeight: calc(var(--cardHeight) * var(--num));
    --carouselWidth: var(--cardHeight);
    --carouselLeftMargin: calc(2vh - var(--cardHeight));
    
    @media screen and (max-height: 500px) { /*basically mobile*/
      --up: 1;
    }
  }
  
  .Wizard_red__3AxTD{
    color:red;
  }`, "",{"version":3,"sources":["webpack://./src/layouts/wizard/Wizard.module.css"],"names":[],"mappings":"AAMA;IACI,yBAAyB;IACzB,cAAc;AAClB;AACA;IACI,0BAA0B;IAC1B,cAAc;AAClB;;;AAGA;IACI,OAAO;IACP,mBAAmB;IACnB,mCAAmC;IACnC,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,yBAAyB;IACzB,kCAAkC;IAClC,sDAAsD;IACtD,kCAAkC;IAClC,mDAAmD;;IAEnD,wCAAwC,mBAAmB;MACzD,OAAO;IACT;EACF;;EAEA;IACE,SAAS;EACX","sourcesContent":["@import url(\"https://fonts.googleapis.com/icon?family=Material+Icons\");\n@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200);\n@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@60,400,0,0');\n\n\n.labels{\n    font-size:1rem !important;\n    color: #344767;\n}\n.smallLabels{\n    font-size:.9rem !important;\n    color: #344767;\n}\n\n\n:root {\n    --up: 2;\n    --card-margin: 12px;\n    --card-shadow: rgba(46, 49, 52, .4);\n    --color-white: #fff;\n    --color-lightgrey: #ccc;\n    --color-ui: #999;\n    --color-ui-hover: #e70808;\n    --cardHeight: calc(96vh/var(--up));\n    --carouselHeight: calc(var(--cardHeight) * var(--num));\n    --carouselWidth: var(--cardHeight);\n    --carouselLeftMargin: calc(2vh - var(--cardHeight));\n    \n    @media screen and (max-height: 500px) { /*basically mobile*/\n      --up: 1;\n    }\n  }\n  \n  .red{\n    color:red;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labels": `Wizard_labels__KBrw4`,
	"smallLabels": `Wizard_smallLabels__fYWiN`,
	"red": `Wizard_red__3AxTD`
};
export default ___CSS_LOADER_EXPORT___;
