/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import styles from '../../layouts/wizard/Wizard.module.css';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Close from '@mui/icons-material/Close';
import ConstructionIcon from '@mui/icons-material/Construction';
import Fade from '@mui/material/Fade';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { v4 as uuid } from 'uuid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';;
import IconButton from '@mui/material/IconButton';
import Box, { BoxProps } from '@mui/material/Box';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';

// react-router-dom components
import xtype from 'xtypejs';
import { DeleteForeverOutlined } from '@mui/icons-material';
function RenderFields({ object, thisRecurLevel, module, onChange, info, recursiveKeyIndex }) {
  const [newValue, setNewValue] = useState('');
  const [recurLevel, setRecurLevel] = useState(thisRecurLevel);
  const [rFObject, setRFObject] = useState(object)
  const [isChecked, setIsChecked] = useState(false)
  const [accordionStates, setAccordionStates] = useState([])
  const emptyElements = {
    "experience": {
      "company": "", "location": "", "position": [{ "title": "", "startedMonthYear": "", "endedMonthYear": "", "currentJob": false, "jobDescription": "", "skills": [], "employmentDurationMonths": null, "employmentDurationMonthsAndYears": null, "noteworthy": "" }]
    },
    "position": {
      "title": "", "startedMonthYear": "", "endedMonthYear": "", "currentJob": false, "jobDescription": "",  "bulletPoints":[""], "employmentDurationMonths": null, "employmentDurationMonthsAndYears": null, "noteworthy": ""
    },
    "skills": [{ "skills": "" }],
    "education": {
      "institution": "", "degree": [{ "degreeName": "", "fieldOfStudy": "", "graduationMonthYear": null,  "inProgress": false, "bulletPoints":[""]}]
    },
    "degree": {
      "degreeName": "", "fieldOfStudy": "", "graduationMonthYear": null,  "inProgress": false, "bulletPoints":[""]},
    "content": ""

  }

  function checkHandler(field,e,index) {
    /*function checkHandler(index){
  
        let nextCounters = counters.map((isChecked, i) => {
          if (i === index) {
            
            return !isChecked;
          } else {
            // The rest haven't changed
            return c;
          }
        });*/
        console.log("isChecked", isChecked)
        console.log("e",e)
        console.log("field", field)
        console.log("index", index)
        handleFieldChange(field, e, index)
    setIsChecked(!isChecked)

    
  }
  const handleNestedFieldChange = (fieldArg, value, index) => {
    handleFieldChange(fieldArg, value, index)

  }

  const handleFieldChange = (fieldArg, value, index) => {


    console.log("fieldArg", fieldArg)
    console.log("info", info)
    console.log("value", value)
    console.log("rFObject", rFObject)

    console.log("object.hasOwnProperty(fieldArg)", rFObject.hasOwnProperty(fieldArg))
    console.log("rFObject", fieldArg)
    console.log("value", value)
    console.log("index", index)
    if (Array.isArray(rFObject)) {
      console.log("Array.isArray(rFObject)", Array.isArray(rFObject))
      // If it's an array, check if the value is empty and that it's not the last element
      if (value === '' && rFObject.length > 1) {
        console.log(" If it's an array, check if the value is empty and that it's not the last element", value)
        console.log("[...rFObject]", Array.isArray([...rFObject]))
        const updatedArray = [...rFObject];
        //const idx = parseInt(index);
        updatedArray.splice(index, 1); // Remove the element at the specified index
        onChange(updatedArray);
        setRFObject(updatedParentObject)
      } else if (value !== '') {
        console.log("Update the specific item and keep it as an array", value)
        // Update the specific item and keep it as an array
        const updatedArray = [...rFObject];
        //const index = parseInt(index); //  field is the index
        updatedArray[index] = value;
        onChange(updatedArray);
        setRFObject(updatedParentObject)
      }
    } else if (xtype.type(rFObject) == 'object') {
      console.log("If it's an object , update the field directly and keep it as an rFObject", value)
      console.log("rFObject", rFObject)
      console.log("{...rfrFObject, [field]: value}", index)
      // If it's an rFObject, update the field directly and keep it as an rFObject

      if (xtype.type(index) == 'number') {
        console.log("///////////////////////////rFObject", rFObject)
        console.log("parentObject rFObject[fieldArg]", rFObject[fieldArg])
        console.log("fieldArg", fieldArg)

        console.log("value", value)
        console.log("value", value)
        let updatedParentObject = { ...rFObject };
        console.log("new updatedParentObject", updatedParentObject)
        updatedParentObject[fieldArg][index] = value
        console.log("updatedParentObject[fieldArg]", updatedParentObject[fieldArg])
        console.log("updatedParentObject", updatedParentObject)

        onChange(updatedParentObject);//send updated object upstream
        setRFObject(updatedParentObject)
      } else {
        console.log("]]]]]]]]]]]]]]]]]]rFObject", rFObject)
        console.log("parentObject rFObject[fieldArg]", rFObject[fieldArg])

        console.log("value", value)
        console.log("value", value)
        let updatedParentObject = { ...rFObject };
        updatedParentObject[fieldArg] = value
        console.log("updatedParentObject[fieldArg]", updatedParentObject[fieldArg])
        console.log("updatedParentObject", updatedParentObject)

        onChange(updatedParentObject);//send updated object upstream
        setRFObject(updatedParentObject)
      }
    }

  }








  const handleAddNewValue = (field, index) => {
    let newElement = { ...emptyElements[field.replace(/([A-Z])/g, ' $1')] }
    console.log("[newElement, ...rFObject[field]", [newElement, ...rFObject[field]])
    console.log("index", index)
    console.log("old Array", rFObject[field])
    console.log("old Array[index]", rFObject[field][index])
    if (field) {
      if (xtype.type(rFObject[field]) == 'array') {

        let updatedArray = [newElement, ...rFObject[field]];

        console.log("updatedArray", Object.keys(updatedArray))
        console.log("old Array", rFObject[field])
        console.log("emptyElements[newValue]", emptyElements[field.replace(/([A-Z])/g, ' $1')])
        console.log("updatedArray", updatedArray)
        console.log("updatedArray", newElement)

        //updatedArray.splice(0, 0, newVal); // add the new value onto the beginning (top) of the array

        handleFieldChange(field, updatedArray); // Update the array in the rFObject
        //onChange(updatedArray);
      }

      console.log("emptyElements[newValue]", Object.keys(emptyElements))

      setNewValue(''); // Reset the new value input
    }
  };

  const handleRemoveValue = (field, key) => {
    console.log("remove", rFObject[field.replace(/([A-Z])/g, ' $1')][key])
    if (field) {
      if (xtype.type(rFObject[field]) == 'array') {
        let updatedArray = [...rFObject[field]];
        console.log("emptyElements[newValue]", emptyElements[field.replace(/([A-Z])/g, ' $1')])
        console.log("updatedArray", updatedArray)
        if (updatedArray[key] || key > -1) { // only splice array when item is found
          updatedArray.splice(key, 1); // 2nd parameter means remove one item only
        }
        console.log("updatedArray", updatedArray)
        handleFieldChange(field, updatedArray); // Update the array in the rFObject
      }

      setNewValue(''); // Reset the new value input
    }
  };
  return (
    <>

      {rFObject &&

        Object.keys(rFObject).map((field, index) => {

          if (xtype.type(rFObject[field]) == 'array') {
            return (

              <MDBox mt={2} ml={2} key={field + String(index)}>
                {console.log("rFObject", rFObject)}
                {console.log("array? rFObject[field]", rFObject[field])}
                <div>
                  <h5>{field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</h5>

                  <MDBox ml={1} mb={2} mt={2}>
                    {console.log("ADD A FUCKING VALUE")}
                    <MDButton mt={20} mb={"10px"} variant="gradient" size="small" color="info" onClick={() => handleAddNewValue(field, index)}>
                      {`Add ${removePlurals(field.replace(/([A-Z])/g, ' $1'))}`}
                    </MDButton>
                  </MDBox>
                  {field.toLowerCase()=="bulletpoints"?
                  
                    <MDBox ml={1} mb={2} mt={2}>
                      {rFObject[field].map((item, index) => {
                        return(
                          <MDBox key={"X"+String(index)}>
                            {console.log("item",item)}
                            {console.log("field",field)}
                            {console.log("index",index)}
                         <MDInput
                          type={"text"}
                          label={`${"Bullet Point "} ${index+1}`}
                          variant="standard"
                          fullWidth
                          name={field}
                          aria-label={field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                          value={item}
                          multiline
                          rows={2}
                          onChange={(e) => handleFieldChange(field, e, false)}
                        />
                  </MDBox> 
                         
              )
              
              })}
                  </MDBox>
                  :rFObject[field].map((item, index) => {
                    console.log("item, ", item)
                    console.log("rFObject[field], ", rFObject[field])
                    console.log("field, ", field)
                    if (!Object.hasOwn(item, 'uuid')) {
                      item.uuid = uuid()
                    }
                    return (
                      <MDBox key={item.uuid}>

                        <Box sx={{ display: 'grid', gridAutoColumns: '10fr', gap: 1, }}>

                          <Box sx={{ mt: "auto", mb: "auto", display: "inline", fontWeight: 'bold', gridRow: '1', gridColumn: 'span 1' }}>
                            <Fab color="red" sx={{ p: "0" }} size="small" aria-label="deleteForever" onClick={() => handleRemoveValue(field, index)}>
                              <Close />
                            </Fab>


                          </Box>
                          <Box sx={{ mt: '10px', gridRow: '1', gridColumn: '2/10' }}>
                            <Accordion   >

                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"

                              >

                                <Typography> {item.title || item.degreeName}</Typography>

                              </AccordionSummary>

                              <AccordionDetails>
                                <Typography>

                                  <RenderFields
                                    object={item}
                                    onChange={(e) => { handleFieldChange(field, e, index) }}
                                    info={{
                                      isAnArray: false,
                                      label: "position".replace(/([A-Z])/g, ' $1'),
                                      arraySize: null,
                                      arrayName: null,
                                    }}

                                    recursiveKeyIndex={index}
                                    thisRecurLevel={recurLevel + 1}
                                  />

                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        </Box>

                      </MDBox>
                    )
                  })
                }



                </div>
              </MDBox>
            );
          } else if (xtype.type(rFObject[field]) === 'object' && rFObject[field]) {
            console.log("rFObject[field] its and rFObject!", rFObject[field])
            console.log("field is", field)
            let valueObject = {}

            return (<MDBox>
              {Object.keys(rFObject[field]).map((nestedField, index) => {
                return (

                  <MDBox mt={1} key={field + String(index)}>
                    {/*this is a wierd work around for formatting to get thie started month year and ended month year in the same grid element, we dont render the startedmonthyear element until we hit the ended monthyear element then do both in one shot*/}


                    {nestedField.toLowerCase() == "graduationmonthyear" ?
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label={"Graduation Month and Year"}
                          views={['month', 'year']}
                          value={dayjs(rFObject[item], "MM-YYYY")}
                          onChange={(newValue) => handleFieldChange(item, dayjs(newValue).format("MM-YYYY"), false)}

                          inputFormat="MM-YYYY"

                        />
                      </LocalizationProvider>
                      : ""}
                    {nestedField.toLowerCase() == 'startedmonthyear'.toLowerCase() ? "" : field.toLowerCase() == 'endedmonthyear'.toLowerCase() ?
                      <Box sx={{ display: 'grid', gridAutoColumns: '5fr', gap: 1, }}>
                        {["startedMonthYear", "endedMonthYear"].map((item, index) => {
                          return (
                            <Box sx={{ mt: "auto", mb: "auto", display: "inline", fontWeight: 'bold', gridRow: '1', gridColumn: (item.toLowerCase() == 'startedmonthyear'.toLowerCase()) ? "1/2 " : "3/4" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label={item.toLowerCase() == 'startedmonthyear'.toLowerCase() ? 'From Month and Year' : 'To Month and Year'}
                                  views={['month', 'year']}
                                  value={dayjs(rFObject[item], "MM-YYYY")}
                                  onChange={(newValue) => handleFieldChange(item, dayjs(newValue).format("MM-YYYY"), false)}

                                  inputFormat="MM-YYYY"

                                />
                              </LocalizationProvider>
                            </Box>
                          );
                        })}

                      </Box> :
                      nestedField.toLowerCase() != 'uuid'.toLowerCase() && nestedField.toLowerCase() !== "employmentDurationMonths".toLowerCase() && nestedField.toLowerCase() !== "employmentDurationMonthsAndYears".toLowerCase() && nestedField.toLowerCase() !== "noteworthy".toLowerCase() ?
                      nestedField.toLowerCase() == 'currentjob'.toLowerCase() ?<MDBox mt={1} ml={2} ><label className={styles.smallLabels} for="currentJob" >  {"I currently work here".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())} </label> <input type="checkbox" id="currentJob" name="currentJob" checked={rFObject[field][nestedField]} onChange={(e)=>{checkHandler(field,!rFObject[field][nestedField],index)}} />        </MDBox>:
                        <MDInput
                          type={"text"}
                          label={nestedField.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                          variant="standard"
                          fullWidth
                          name={nestedField}
                          aria-label={nestedField.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                          value={rFObject[field][nestedField]}
                          multiline
                          onChange={(e) => handleFieldChange(nestedField, e, false)}
                        /> : ""}
                      
          

                  </MDBox>

                )
              })}

            </MDBox>
            );

          } else if (xtype.type(rFObject[field]) == "array" && parseInt(field) < info.arraySize - 1) {
            console.log("info?.isAnArray")
            console.log("info", info)
            console.log("its and rFObject!", rFObject[field])
            return ("");

          } else if (typeof rFObject[field] === 'object' && rFObject[field]) {
            return (

              <MDBox mt={2} ml={2} key={field + String(index)}>
                {console.log("fuck again again")}
                <div>
                  <h5>{field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</h5>
                  <RenderFields object={rFObject[field]} onChange={(e) => handleFieldChange(field, e, false)} thisRecurLevel={recurLevel + 1} />
                </div>
              </MDBox>
            );
          } else {
            return (
              <MDBox mt={2} key={field + String(index)}>
                {console.log("fuck again again default")}

                {field.toLowerCase() == 'startedmonthyear'.toLowerCase() ? "" : field.toLowerCase() == 'endedmonthyear'.toLowerCase() ?
                  <Box sx={{ display: 'grid', gridAutoColumns: '5fr', gap: 1, }}>
                    {["startedMonthYear", "endedMonthYear"].map((item, index) => {
                      return (

                        <Box sx={{ mt: "auto", mb: "auto", display: "inline", fontWeight: 'bold', gridRow: '1', gridColumn: (item.toLowerCase() == 'startedmonthyear'.toLowerCase()) ? "1/3" : "3/5" }}>

                          {console.log("aaaaaaaaaaaaaaaaaaaaaaaitem", item)}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label={item.toLowerCase() == 'startedmonthyear'.toLowerCase() ? 'From Month and Year' : 'To Month and Year'}
                              views={['month', 'year']}
                              value={dayjs(rFObject[item], "MM-YYYY")}
                              onChange={(newValue) => handleFieldChange(item, dayjs(newValue).format("MM-YYYY"), false)}

                              inputFormat="MM-YYYY"

                            />
                          </LocalizationProvider>


                        </Box>

                      );
                    })}

                  </Box> :



                  field.toLowerCase() != 'uuid'.toLowerCase() && field.toLowerCase() !== "employmentDurationMonths".toLowerCase() && field.toLowerCase() !== "employmentDurationMonthsAndYears".toLowerCase() && field.toLowerCase() !== "noteworthy".toLowerCase() ?

                  field.toLowerCase() == 'currentjob'.toLowerCase() ?<MDBox mt={1} ml={2} ><label className={styles.smallLabels} for="currentJob" >  {"I currently work here".replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())} </label> <input type="checkbox" id="currentJob" name="currentJob" checked={rFObject[field]} onChange={(e)=>{checkHandler(field,!rFObject[field],false)}}/>        </MDBox>:
                    <MDInput
                      type="text"
                      label={field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}s
                      variant="standard"
                      fullWidth
                      name={field}
                      aria-label={field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                      value={rFObject[field] || ''}
                      multiline
                      onChange={(e) => handleFieldChange(field, e.target.value, false)}
                    /> : ""}

              </MDBox>
            );
          }
        })}
    </>
  );
}

function removePlurals(inputString) {
  const pluralEndingsMap = {
    s: '', // Remove 's' to make it singular
    es: '', // Remove 'es' to make it singular
    ies: 'y', // Replace 'ies' with 'y'
  };

  for (const ending in pluralEndingsMap) {
    if (inputString.endsWith(ending)) {
      return inputString.slice(0, -ending.length) + pluralEndingsMap[ending];
    }
  }

  return inputString;
}

RenderFields.defaultProps = {
  formObject: {},
  recursiveParentObject: false,
  recursiveKeyIndex: false,
  thisRecurLevel: 0,

};

RenderFields.propTypes = {
  object: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  recursiveKeyIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  info: PropTypes.object,
  thisRecurLevel: PropTypes.number
};

export default RenderFields;
