/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
import {useState, useEffect} from 'react';
import {getProfile, updateProfile} from '../../profile'
// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import MuiLink from '@mui/material/Link';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import InteractiveList from 'components/JobList';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDLoading from 'components/MDLoading';
import {getJob, deleteJob, archiveJob, generateCoverLetter} from '../../jobs';
// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';
import DataTable from 'tongoComponents/Tables/DataTable';
import JobInfo from 'layouts/shortList/view/components/JobInfo';
import { useParams } from 'react-router';
import ViewApplication from 'layouts/shortList/view';
// Data
import jobListData from 'layouts/shortList/data/jobListData';
import select from 'assets/theme/components/form/select';


function JobShortList() {
  const { jobId } = useParams();
  const [appLoading, setAppLoading] = useState(true);
  const [appTable, setAppTable] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobIndexIdMap, setJobIndexIdMap] = useState({});
  const [jobIdIndexMap, setJobIdIndexMap] = useState({});
  const [resume, setResume] = useState({});
  const [fetched, setFetched] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Loading');
  const [jobsCache, setJobsCache] = useState([]);

  const [errorSB, setErrorSB] = useState();
  const closeError = () => setErrorSB();





  useEffect(() => {
    
    if (!fetched) {


      setLoading('Loading...');
      const fetchData = async () => {
        try {
        
            const data = await getJob(
              null,
              '{"where":{"archived":{"neq":true}},"fields": {"id":true,"jobTitle":true,"companyName":true,"jobLocation":true,"coverLetterStatus":true,"created":true,"jobPostingUrl":true}}'
            );

            {data? setJobsCache([...data]):console.log("fuck")}
            {data? setRows([...data]):console.log("fuck")}
          

          console.log("rows[0]");

    
    

    
         
          console.log("stringify ", JSON.stringify(rows))


          console.log("************************************data",data)
          console.log("************************************jobsCache",jobsCache)

          console.log("************************************rows",rows)



          let indexIdMap=[]
          let idIndexMap={}
          for (let i = 0; i < rows.length; i++) {
            indexIdMap[i] =rows[i].id
            idIndexMap[rows[i].id]=i
            console.log("rows[",i,"]",rows[i]);
            console.log("rows[",i,"] index: ",rows[i].index)
            console.log("rows[",i,"] job: ",rows[i].job)
            console.log("rows[",i,"] job id: ",rows[i].id)
           
          }
    
          setJobIdIndexMap({...idIndexMap})
      
               
        }catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
  
      getProfile({
        fields: {
          resumeParsed: true,
          avatar: true,
          salutation: true,
          firstName: true,
          lastName: true,
          middleName: true,
          title: true,
          bio: true,
          telephone: true,
          email: true,
          city: true,
          state: true,
          country: true,
          social: true,
          resumeKeywords: true,
          resumeUpdated: true,
          emailVerified: true,
        },
      })
        .then((r) => {
          console.log("WTFUCKKKKKKKKKKKKKKKKKKKKKKKKKKKKKthen((r) ",r)
          if (r?.resumeParsed){
            console.log("WTFUCKKKKKKKKKKKKKKKKKKKKKKKKKKKKK r?.resumeParsed")
            console.log("WTFUCKKKKKKKKKKKKKKKKKKKKKKKKKKKKK r?.resumeParsed",r?.resumeParsed)
            setResume(r);
            console.log("resume: ", resume)
          //update local storage user info
          localforage.getItem('user').then((user) => {
            localforage.setItem('user', {...user, ...r});
          });
          console.log("SHORTLISTuser: ", user)
        

          setFetched(true);}
        })
        .catch((error) => {
          if ([401, 404].includes(error.code)) {
            // Perform the redirect to the login screen
            console.log("$$$$error is: "+error)
            window.location.href = '/sign-in';
          } else {
            setErrorSB([{title: 'Error retrieving your account!', message: `Please review your info and try again! (${error})`}]);
            // Handle other errors
          }
        })
        .finally(() => {
          setLoading(false);
          
        });
    }
  }, [fetched]);
  console.log("the id passed as a param is "+JSON.stringify({jobId})+"??????????????????!!!!!!!!!!!!!!!!!!")

  const handleDataLoaded = (isLoading, tableData,indexIdMap) => {
    setAppLoading(isLoading);
    setAppTable(tableData);
    setJobIndexIdMap(indexIdMap);
    
    (selectedJobId?console.log("selected job id", selectedJobId):(jobId? setSelectedJobId(jobId):setSelectedJobId(jobIndexIdMap[JSON.stringify(0)])));
  };

  function handleSelectJobByIndex(jobIndex){

    setSelectedJobId(jobIndexIdMap[JSON.stringify(jobIndex)]);
    /*setSelectedJobIndex(JSON.stringify(jobIndex));*/
  }

  jobListData(handleDataLoaded);
const style = {
    py: 0,
    width: '100%',
    minWidth: 500,
    borderRadius: 2,
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: 'background.paper',
    listStyleType: 'none',
    pt:3,
    pb:0
  };

  const testArray = [

    {primary:"primary1", secondary:"secondary1"},
    {primary:"primary2", secondary:"secondary2"},
    {primary:"primary3", secondary:"secondary3"},
    {primary:"primary4", secondary:"secondary4"},
    {primary:"primary5", secondary:"secondary5"},
    {primary:"primary6", secondary:"secondary6"}


  ]
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={0} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={4}>
           
              <MDBox pt={0}>

                {selectedJobId!== null && selectedJobId!== undefined? console.log("job selected ", selectedJobId):console.log("no job selected ",selectedJobId)}
                { console.log("selectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobId",selectedJobId)}
                {console.log("selectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIdjobIdIndexMap",jobIdIndexMap)}
                {console.log("selectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIndexselectedJobIdjobIdIndexMap[selectedJobId]",jobIdIndexMap[selectedJobId])}
                                  
                {appLoading ? (
                  <MDLoading open={true} />
                ) : appTable?.rows && appTable.rows.length !== 0 ? ( // Check if rows is not null and its length is not 0
                               <InteractiveList list={rows} accessor={"job"}  primaryText={"jobTitle"} secondaryText={"company"} selectedJobId={selectedJobId} handleSelectJobByIndex={handleSelectJobByIndex}/>  
                /*<DataTable table={appTable} isSorted={true} entriesPerPage={true}  onSwipePage={false} showTotalEntries={true} canSearch={true}  handleSelectJobByIndex={handleSelectJobByIndex} noEndBorder />*/
                ) : (
                  <MDBox mx={2} mt={3} py={3} px={2} pb={0} pt={0}>
                    <MDTypography variant="body2" color="warning">
                      No job list is available
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>

                     </Grid>
          <Grid item xs={8}>
          {appLoading ? (
                  <MDLoading open={true} />
               ) : appTable?.rows && appTable.rows.length !== 0 ? ( // Check if rows is not null and its length is not 0
                <ViewApplication resume={resume} jobId={selectedJobId}/>
                ) : (
                  <MDBox mx={2} mt={3} py={3} px={2} pb={0} pt={0}>
                    <MDTypography variant="body2" color="warning">
                      No jobs yet!
                    </MDTypography>
                    <MuiLink href="short-list/new" rel="noreferrer">
                                  <MDButton size="medium" color="info">
                                    Add a Job:
                
                
                                  </MDButton>
                                </MuiLink>
                  </MDBox>
                           
                 
                    
                )}
          
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default JobShortList;
