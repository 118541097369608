/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState, useEffect} from 'react';
import {updateProfile} from '../../../../profile';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function PlatformSettings(settings) {
  const [searchProfileMatch, setSearchProfileMatch] = useState(settings.searchProfileMatch ?? true);
  const [searchKeywordMatch, setSearchKeywordMatch] = useState(settings.searchKeywordMatch ?? true);
  const [applicationTrack, setApplicationTrack] = useState(settings.applicationTrack ?? true);
  const [applicationInterviewDate, setApplicationInterviewDate] = useState(settings.applicationInterviewDate ?? true);
  const [newsletter, setNewsletter] = useState(settings.newsletter ?? true);
  const [save, setSave] = useState(false);

  const handleSave = (e) => {
    const {name} = e.target;

    switch (name) {
      case 'newsletter':
        setNewsletter(!newsletter);
        break;
      case 'applicationInterviewDate':
        setApplicationInterviewDate(!applicationInterviewDate);
        break;
      case 'applicationTrack':
        setApplicationTrack(!applicationTrack);
        break;
      case 'searchKeywordMatch':
        setSearchKeywordMatch(!searchKeywordMatch);
        break;
      case 'searchProfileMatch':
        setSearchProfileMatch(!searchProfileMatch);
        break;
    }
    setSave(true);
  };

  useEffect(() => {
    if (save) {
      const settings = {
        searchProfileMatch: searchProfileMatch,
        searchKeywordMatch: searchKeywordMatch,
        applicationTrack: applicationTrack,
        applicationInterviewDate: applicationInterviewDate,
        newsletter: newsletter,
      };
      updateProfile({settings: settings})
        .then((result) => {
          //reload
        })
        .catch((error) => {
          // setErrorSB([{ title: 'Error saving your profile', message: error }]);
        })
        .finally(() => {
          setSave(false);
        });
    }
  }, [searchProfileMatch, searchKeywordMatch, applicationTrack, applicationInterviewDate, newsletter]);

  return (
    <Card sx={{boxShadow: 'none'}}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          general and notification settings
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          General
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch name="newsletter" checked={newsletter} onChange={handleSave} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Subscribe to newsletter
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          Job Searches Notifications
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch name="searchProfileMatch" checked={searchProfileMatch} onChange={handleSave} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email me when a job search matches my résumé and profile
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch name="searchKeywordMatch" checked={searchKeywordMatch} onChange={handleSave} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email me when a job search matches my keywords
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox mt={3}>
          <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
            Job applications notifications
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch name="applicationTrack" checked={applicationTrack} onChange={handleSave} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Automatically track my application.
              <br />
              <MDTypography variant="caption" fontWeight="regular" color="text">
                Tongo will send on your behalf and forward messages from potential employers
              </MDTypography>
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={0.5}>
            <Switch name="applicationInterviewDate" checked={applicationInterviewDate} onChange={handleSave} />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Email me ahead of job interviews
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PlatformSettings;
