/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// @mui material components
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import Grid from '@mui/material/Grid';
// Material Dashboard 2 React example components
import CustomSectionTimelineItem from 'tongoComponents/TongoTimeline/CustomSectionTimelineItem';
import CustomSectionTimelineList from 'tongoComponents/TongoTimeline/CustomSectionTimelineList';
import ActionMenu from 'tongoComponents/ActionMenu';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

function CustomSection({ customSection, keyval, key, index, arr, moveElementUp, moveElementDown, deleteElement, editElementItem }) {
  const module = 'custom';
  
  const tasks = [
    {
      label: 'Edit',
      icon: 'edit_note',
      action: (module, id, updatedElement) => {
        editElementItem(module, index, updatedElement);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Delete',
      icon: 'delete',
      action: (id) => {
        deleteElement(module, id);
      },
      confirmation: true,
      confirmationMessage: `Are you sure you want to delete this ${module}? This action cannot be undone.`,
    },
    {
      label: 'Move up',
      icon: 'move_up',
      action: (id) => {
        moveElementUp(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Move down',
      icon: 'move_down',
      action: (id) => {
        moveElementDown(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];

  const Addtask = [
    {
      label: 'Add new',
      icon: 'add',
      action: (module, id, newEducation) => {
        editElementItem(module, (id = 0), newEducation);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];

  const handleEditActionMenu = ( index, updatedElement) => {
    console.log("blarggggmodule, module, index, updatedElement",module, index, updatedElement)
    editElementItem(module, index, updatedElement);
  };
  const renderCustomSection = (customSection, index, totalCustom) => {
    if (!customSection) {
      return null; // Return null if customSection is not defined
    }

    const isLastItem = index === totalCustom - 1;

    

    delete customSection.projects;
    return (
      <MDBox key={index} display="flex" justifyContent="space-between" alignItems="center">
        {console.log('customSection', customSection)}


        <CustomSectionTimelineItem
          key={index}
          color="success"
          icon="work_history"
          customSection={customSection}
          title={`${customSection.title}`}
          
          // eslint-disable-next-line react/prop-types
          description={customSection.dutiesAccomplishment?.dutiesDescription ? customSection.dutiesAccomplishment.dutiesDescription :""}
          lastItem={isLastItem} // Pass lastItem prop if it's the last item
        />
        <ActionMenu id={index} tasks={tasks} handleEditActionMenu={handleEditActionMenu}  onActionCompleted={() => {}} formObject={{title: module, obj: customSection}} />
      </MDBox>
    );
  };


  return (
    <Card sx={{height: '100%'}}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Custom Sections
        </MDTypography>
        <ActionMenu id={0} handleEditActionMenu={handleEditActionMenu}  onActionCompleted={() => {}} tasks={Addtask} formObject={{title: module, obj: emptyCustomSection}} />
      </MDBox>
      <CustomSectionTimelineList title="Custom Sections">
        {customSection && customSection.length > 0
          ? customSection.map((e, index) => {
              return renderCustomSection(e, index, customSection.length);
            })
          : ''}
      </CustomSectionTimelineList>
    </Card>
  );
}

export default CustomSection;

CustomSection.defaultProps = {
  tit: '',
  content: '',
};

CustomSection.propTypes = {
  customSection: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,

      content: PropTypes.string,
    })
  ).isRequired,
  moveElementUp: PropTypes.func,
  moveElementDown: PropTypes.func,
  deleteElement: PropTypes.func,
  editElementItem: PropTypes.func,
};

const emptyCustomSection = {
  title: '',
  content: '',
};





