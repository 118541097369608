/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
import dayjs from 'dayjs';
// @mui material components
import Card from '@mui/material/Card';
import Box, { BoxProps } from '@mui/material/Box';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import TimelineItem from 'tongoComponents/Timeline/TimelineItem';
import TimelineList from 'tongoComponents/Timeline/TimelineList';
import ActionMenu from 'tongoComponents/ActionMenu';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

function Experience({experience, moveElementUp, moveElementDown, deleteElement, editElement}) {
  const module = 'experience';
  const emptyExperience = {
    "company": "", 
    "location": "",
     "position": [{ 
        "title": "",
        "startedMonthYear": "", 
        "endedMonthYear": "", 
        "currentJob": false, 
        "jobDescription": "",
         "skills": [],  
         "employmentDurationMonths": null, 
         "employmentDurationMonthsAndYears": null, 
         "noteworthy": "" }]
  }
  
  const tasks = [
    {
      label: 'Edit',
      icon: 'edit_note',
      action: (module, id, updatedElement) => {
        editElement(module, id, updatedElement);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Delete',
      icon: 'delete',
      action: (id) => {
        deleteElement(module, id);
      },
      confirmation: true,
      confirmationMessage: `Are you sure you want to delete this ${module}? This action cannot be undone.`,
    },
    {
      label: 'Move up',
      icon: 'move_up',
      action: (id) => {
        moveElementUp(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Move down',
      icon: 'move_down',
      action: (id) => {
        moveElementDown(module, id);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];

  const Addtask = [
    {
      label: 'Add new',
      icon: 'add',
      action: (module, id, newExperience) => {
        editElement(module, (id = -1), newExperience);
      },
      confirmation: false,
      confirmationMessage: '',
    },
  ];

  const renderExperience = (experience, index, totalExperience) => {
    console.log("renderExperience: ")
    console.log("experience: ",experience)
    console.log("index: ",index)
    console.log("totalExperience: ",totalExperience)
    if (!experience) {
      return null; // Return null if experience is not defined
    }

    const isLastItem = index === totalExperience - 1;

    const dateText = (experience.duration ? `(${experience.duration})` : '') + (experience.location ? ` - ${experience.location}` : '');
    /*let positionList=[]
    experience.position.map(item){
      positionList.push(item.title)
    }*/
    let tempArray=[]

    if(experience.position){
      for (let i = 0; i < experience.position.length; i++) {
        let str = (String(experience.position[i].endedMonthYear).toLowerCase()=="present")?String(experience.position[i].endedMonthYear):String(dayjs(experience.position[i].endedMonthYear, "MM-YYYY").format("MMMM YYYY"));
        console.log ("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA ", str)
        tempArray.push(String(experience.position[i].title) +" "+String(dayjs(experience.position[i].startedMonthYear, "MM-YYYY").format("MMMM YYYY"))+" - "+str);
      }
    }
    const positionArr=[...tempArray]
    console.log("positionArr: ",positionArr)
    delete experience.projects;
    return (
      
      <MDBox key={index} display="flex" justifyContent="space-between" alignItems="center">
       

        <TimelineItem
          key={index}
          color="success"
          icon="work_history"
          title={`${experience.company}`}
          subtitleList={positionArr}
          dateTime={""}
          // eslint-disable-next-line react/prop-types
          description={experience.jobDescription}
          //description={experience.dutiesAccomplishment.dutiesDescription || experience.dutiesAccomplishment.summary}
          lastItem={isLastItem} // Pass lastItem prop if it's the last item
        />
        <ActionMenu id={index} tasks={tasks} onActionCompleted={() => {}} formObject={{title: module, obj: experience}} />
      </MDBox>
    );
  };

  return (
    <Card sx={{height: '100%'}}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Experience
        </MDTypography>
        <ActionMenu id={0} onActionCompleted={() => {}} tasks={Addtask} formObject={{title: module, obj: emptyExperience}} />
      </MDBox>
      <TimelineList title="">
        {experience && experience.length > 0
          ? experience.map((e, index) => {
              return renderExperience(e, index, experience.length);
            })
          : ''}
      </TimelineList>
    </Card>
  );
}

export default Experience;

Experience.propTypes = {
  experience: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      dutiesAccomplishment: PropTypes.shape({
        summary: PropTypes.string,
        dutiesDescription: PropTypes.arrayOf(PropTypes.string),
      }).isRequired,
    })
  ).isRequired,
  moveElementUp: PropTypes.func,
  moveElementDown: PropTypes.func,
  deleteElement: PropTypes.func,
  editElement: PropTypes.func,
};

