/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import { getProfile, generateResumeKeywords, updateProfile, uploadResume } from '../../../../profile';
import BasicModal from 'components/BasicModal';
import React, { Component } from "react";
import { render } from "react-dom";
import FormControl from '@mui/material/FormControl';
import { styles } from './Resume.module.css';
import { saveAs } from "file-saver";

import { Packer } from "docx";
//import { experiences, education, skills, achievements } from "../WordExport/cv-data";
import { WordExport } from "../WordExport";
// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import DescriptionIcon from '@mui/icons-material/Description';
import CircularProgress from '@mui/material/CircularProgress';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate
} from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';
import MDBadge from 'components/MDBadge';


import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// Material Dashboard 2 React context
import { useMaterialUIController } from 'context';
import MDInput from 'components/MDInput';
import ResumeWizard from 'layouts/wizard/ResumeWizard';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import AnimatedToolTip from 'tongoComponents/AnimatedToolTip';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import linkedInResumeToolTipGif from 'assets/images/linkedInResumeToolTip.gif';
function ResumeTools({ keywords, mainResume, resumeUpdated, reload }) {

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [resumeError, setResumeError] = useState(false);
  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const [resume, setResume] = useState(mainResume);
  const [resumeDate, setResumeDate] = useState(resumeUpdated);
  const [resumeKeywords, setResumeKeywords] = useState(keywords);

  const [loading, setLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [update, setUpdate] = useState(true);
  const [status, setStatus] = useState('');
  const handleSetUpdate = () => setUpdate(!update);

  const navigate = useNavigate();


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        minHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        padding: 50,
        width: 250,
      },
    },
  };

  useEffect(() => {
    setResume(mainResume);
    setResumeDate(resumeUpdated);
    setResumeKeywords(keywords);
    if (status !== 'ok') getStatus();
  }, [keywords, resumeUpdated]);

  const handleOnSave = () => {
    setLoading('Saving keywords...');

    updateProfile({ resumeKeywords: resumeKeywords })
      .then((result) => {
        setResumeDate(new Date());
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: `Error saving keywords`,
            message: error.message,
          },
        ]);
        reload(false);
      })
      .finally(() => {
        setLoading(false);
        setResumeError(false);
      });
  };
  console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX resumeUpdated", mainResume)
  const handleOnDownload = () => {
    //TODO - implement onDownload
    alert('Feature coming soon!\nThe download feature is coming soon.');

    //   updateProfile({ mainResume: updatedResume, mainResumeUnformatted: updatedResumeUnformatted })
    //     .then((result) => {
    //       setResumeDate(new Date.now());
    //     })
    //     .catch((error) => {
    //       setErrorSB([{ title: 'Error saving résumé', message: error }]);
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //     });
    // }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleClose = () => setOpenDialog(false);

  const handleUpload = () => {
    if (!selectedFile) {
      alert('Please select a file before uploading.');
      return;
    }

    setLoading('Uploading and parsing your résumé...\nThis operation may take some time but you will receive a notification when done.');
    setOpenDialog(false);

    uploadResume(selectedFile, update)
      .then((result) => {
        setSelectedFile(null);
        setResumeDate(Date.now());
        setMessageSB([
          {
            color: 'success',
            icon: 'check',
            title: `Résumé was uploaded successfully!`,
            message: 'Processing will continue in the background, please check status ',
          },
        ]);
        reload(false);
        getStatus();
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: `Error uploading résumé`,
            message: error.message,
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
        setOpenDialog(false);
      });
  };

  const genKeywords = () => {
    setLoading('Generating keywords from your résumé...');

    generateResumeKeywords()
      .then((result) => {
        setResumeKeywords(result);
        setMessageSB([
          {
            color: 'success',
            icon: 'check',
            title: `Success!`,
            message: 'Résumé keywords were created',
          },
        ]);
        reload(false);
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: `Error generating résumé keyword`,
            message: error.message,
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      autoHide={5000}
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );

  const renderDate = (date) => {
    console.log('resume', resume);
    return date
      ? new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
      : 'No update yet';
  };

  const renderResumeStatus = () => {
    switch (status) {
      case 'notStarted':
        return <MDBadge badgeContent="No résumé" color="error" variant="gradient" size="md" />;
      case 'uploaded':
        return <MDBadge badgeContent="résumé uploaded" color="info" variant="gradient" size="md" />;
      case 'inProgress':
        return (
          <>
            <MDBadge badgeContent="résumé parsing in progress" color="info" variant="gradient" size="md" />
            <CircularProgress color="info" />
          </>
        );
      case 'noResults':
        return <MDBadge badgeContent="cannot process résumé" color="secondary" variant="gradient" size="md" />;
      case 'failed':
        return <MDBadge badgeContent="parsing failed" color="warning" variant="gradient" size="md" />;
      case 'ok':
        return <MDBadge badgeContent="Résumé OK" color="success" variant="gradient" size="lg" />;
      default:
      case 'pending':
        return (
          <>
            <MDBadge badgeContent="Résumé parsing pending" color="info" variant="gradient" size="xs" />
            <CircularProgress size={1} color="info" />
          </>
        );
    }
  };

  const getStatus = () => {
    const fetchStatus = () => {
      getProfile({ fields: { resumeStatus: true } })
        .then((s) => {
          setStatus(s.resumeStatus);
          if (s.resumeStatus === 'ok') {
            clearInterval(statusCheckInterval); // Clear the interval when status is 'ok'
            reload(true);
          }
        })
        .catch(() => { });
    };

    // Make the initial call to fetchStatus
    fetchStatus();

    let statusCheckInterval;

    // Set up the interval if the initial status is not 'ok'
    if (status !== 'ok') {
      statusCheckInterval = setInterval(fetchStatus, 5000); // Retry every 5 seconds
    }
  };
  function generate() {

    //let skills = resume?.resumeParsed[3].customFields//custom fields for now
    const exp = new WordExport();
    
      const doc = exp.create(resume);

    Packer.toBlob(doc).then(blob => {
      console.log(blob);
      saveAs(blob, "example.docx");
      console.log("Document created successfully");
    });
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });



  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }



  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>
      {' '}
      {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
      <MDLoading text={loading === false ? '' : loading} open={Boolean(loading)} />
      {openDialog ? (
        <Dialog open={openDialog} onClose={handleClose} aria-labelledby="Alert confirmation" aria-describedby="Upload confirmation">
          <DialogTitle>Upload résumé</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select a PDF document exported from LinkedIn. The document must have a Contact, Experience and Education sections.
              <br /> Once uploaded, we will convert the document into a Tongo résumé.
            </DialogContentText>

            <MDBox mt={4}>
              <MDButton variant="gradient" component="label" color={darkMode ? 'dark' : 'info'} startIcon={<Icon>attach_file</Icon>}>
                <input
                  type="file"
                  accept=".pdf" // Define the allowed file types
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="fileInput"
                />
                Choose résumé file
              </MDButton>
              <MDTypography variant="body" color="text" ml={5}>
                {selectedFile ? selectedFile.name : 'No file selected'}
              </MDTypography>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch color="info" name="update" checked={update} onChange={handleSetUpdate} />
                <MDTypography variant="button" fontWeight="regular" color="text" onClick={handleSetUpdate} sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}>
                  &nbsp;&nbsp;Update my account with info from my résumé
                </MDTypography>
              </MDBox>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton variant="gradient" color="info" onClick={handleUpload} disabled={!Boolean(selectedFile)}>
              Upload
            </MDButton>
            <MDButton variant="gradient" color="success" onClick={handleClose} autoFocus>
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      ) : null}
      {Boolean(resumeError) && (
        <Grid item xs={12} sm={6} lg={3}>
          <MDButton variant="gradient" color="error" fullWidth>
            You must create or upload a LinkedIn PDF résumé before using Tongo AI
          </MDButton>
        </Grid>
      )}
<MDBox mt={4}>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch color="info" name="update" checked={update} onChange={handleSetUpdate} />
          <MDTypography variant="button" fontWeight="regular" color="text" onClick={handleSetUpdate} sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}>
            &nbsp;&nbsp;Update my account with info from my résumé
          </MDTypography>
        </MDBox>
      </MDBox>


      <MDBox mb={0}>
        <Grid>
          <Card id="job-info">
            <MDBox pt={0} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                Résumé Tools
              </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={darkMode ? 'transparent' : 'grey-100'}
                  borderRadius="lg"
                  p={3}
                  mb={1}
                  mt={2}
                >
                  <MDBox width="100%" display="flex">
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      mb={0}
                    >
                      <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                        {name}
                      </MDTypography>
                      <MDBox display="fix" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
                        <MDBox mt={3} ml={10}>

                          Tongo needs your resume to help you find and apply to jobs. Choose one:
                          <MDBox display="fix" alignItems="center" mt={{ xs: 2, sm: 0, md: 3 }} ml={{ xs: -1.5, sm: 0 }} >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                p: 1,
                                m: 1,

                                borderRadius: 1,
                              }}
                            ><Box>
                                <MDButton
                                 variant="outlined"
                                 color="info"
                                  component="label"
                                  role={undefined}
                                 
                                  size="large"
                                  tabIndex={-1}
                           
                                  startIcon={<CloudUploadIcon />}
                                  onClick={() => setOpenDialog(true)}>
                                  

                                  Upload a LinkedIn PDF resume
                                </MDButton>
                              </Box>
                              <AnimatedToolTip toolTipGif={linkedInResumeToolTipGif} />
                              <Box ml={{ xs: 2, sm: 5 }}>
                              <MDButton
                                 variant="outlined"
                                 color="info"
                                  component="label"
                            
                                  size="large"
                   
      
                                  tabIndex={-1}
                                   
                                  onClick={() => { navigate("/resume/wizard") }}>

                                  Create or Update a Resume in 5 min
                                </MDButton>
                              </Box>
                            </Box>
                          </MDBox>
                          <MDBox display="fix" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>


                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                p: 1,
                                m: 1,

                                borderRadius: 1,
                              }}
                            >
                           
                              <MDBox mt={4}>
         

         </MDBox>
                              {/*<Box>
                              
                                <Button
                                  component="label"
                                  role={undefined}
                                  variant="contained"
                                  size="large"
                                  tabIndex={-1}
                                  startIcon={<CloudUploadIcon />}
                                  onClick={() => setOpenDialog(true)}>


                                  Upload a Formatted PDF Resume
                                </Button>


                              </Box>*/}
                              <Box ml={{ xs: 2, sm: 5 }}>

                              <MDButton
                                variant="outlined"
                                color="info"
                           
                                  onClick={generate}
                                  component="label"
                             
                                  size="large"
                          
                               
                                  tabIndex={-1}
                                  startIcon={<DescriptionIcon />}>
                                  Download Tongo's Resume as a Word Document (docx)

                                  </MDButton>
                              </Box>
                            </Box>

                            {/*            <Box ml={{xs: 2, sm: 5}}>
                      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Select Resume Version</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          SelectDisplayProps={{ style: { minHeight:"50px",paddingTop: "8px", paddingBottom: 8 } }}
          value={personName}
         
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
          </Box>*/}
                          </MDBox>



                          {/* <MDButton variant="text" color="info">
                            <Icon>account_tree</Icon>&nbsp;résumé wizard
                          </MDButton>
   



                          <MDButton variant="text" color="info">
                            <Icon>picture_as_pdf</Icon>&nbsp;download Tongo&apos;s résumé
                          </MDButton>
                          <MDButton variant="text" color="info">
                            <Icon>picture_as_pdf</Icon>&nbsp;download uploaded résumé
                          </MDButton>*/}
                          {!resumeKeywords?.length ? (
                            <MDButton
                              variant="text"
                              color="info"
                              size="small"
                              onClick={() => {
                                genKeywords();
                              }}
                            >
                              <Icon>search</Icon>
                              {' Update job search keywords'}
                            </MDButton>
                          ) : null}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>

                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor="transparent"
                  borderRadius="lg"
                  p={0.1}
                  mb={0.1}
                  mt={0.1}
                >
                  <MDBox mt={0} width="100%" display="flex" flexDirection="column">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      size="medium"
                      options={resumeKeywords ?? []}
                      value={resumeKeywords ?? []} // Set the defaultValue to the array of keywords
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" label="Résumé / Job search keywords" placeholder="Keywords" fullWidth onBlur={handleOnSave} />
                      )}
                      limitTags={10}
                      freeSolo
                      onChange={(event, newValue) => setResumeKeywords(newValue)}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mt={3} lineHeight={1}>
                  <MDTypography variant="caption" color="text">
                    Résumé last updated:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                      {renderDate(resumeDate)}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox>{status ? renderResumeStatus(status) : null}</MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>

      </MDBox>
    </>
  );
}

export default ResumeTools;

ResumeTools.propTypes = {
  keywords: PropTypes.array,
  resumeUpdated: PropTypes.string,
  reload: PropTypes.func,
};
