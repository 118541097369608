/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import {getProfile} from '../../profile';
import {useState, useEffect} from 'react';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDLoading from 'components/MDLoading';
import MDSnackbar from 'components/MDSnackbar';

// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';

// Overview page components
import Header from 'layouts/account/components/Header';

function Account() {
  const [profile, setProfile] = useState({});
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);

  const [errorSB, setErrorSB] = useState([]);
  const closeError = () => setErrorSB([]);

  useEffect(() => {
    if (!fetched) {
      getProfile()
        .then((profile) => {
          setProfile(profile);
          setFetched(true);
        })
        .catch((error) => {
          if ([401, 404].includes(error.code)) {
            // Perform the redirect to the login screen

            window.location.href = '/sign-in';
          } else {
            setErrorSB([{title: 'Error retrieving your account!', message: `Please review your info and try again! (${error})`}]);
            // Handle other errors
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const renderError = (title, message) => (
    <MDSnackbar
      color="error"
      icon="error"
      title={title}
      content={message}
      dateTime=""
      open={Boolean(errorSB)}
      onClose={closeError}
      close={closeError}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDLoading disabled open={loading} onClose={() => {}} />
      {errorSB.length ? renderError(errorSB[0].title, errorSB[0].message) : null} <MDBox mb={2} />
      {fetched && <Header {...profile} />}
      <Footer />
    </DashboardLayout>
  );
}

export default Account;
