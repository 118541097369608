/*redux slice for search terms, search query status, and results*/
import { createSlice } from '@reduxjs/toolkit'

const searchSlice = createSlice({ 
        name: 'search', 
        initialState: {
            terms: '', status: 'idle', results: [], 
        },
        reducers: { 
            setTerms: (state, action) => { 
                state.terms = action.payload; }, 
            //setLastPromiseInfo: (state, action) => {
            //    state.status = action.payload; }, 
            setResults: (state, action) => { 
                state.results = action.payload; }, 
                setSearchData: (state, action) => {
                    state.data = action.payload;
                  },
                  setSearchStatus: (state, action) => {
                    state.status = action.payload;
                  
                },
        }, 
    });

export const { setTerms,setSearchStatus,setSearchData, setResults } = searchSlice.actions;

export default searchSlice.reducer;