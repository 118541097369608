/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
import {updateProfile} from '../../../../profile';

// react-routers components
import {useState} from 'react';
// prop-types is library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import InputLabel from '@mui/material/InputLabel';

import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from '@mui/material';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';

import stateOptions from '../../../Misc/states';

// Material Dashboard 2 React base styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';

function ProfileInfoCard({cardTitle, bio, info, social, action, shadow}) {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const labels = [];
  const values = [];
  const {socialMediaColors} = colors;
  const {size} = typography;
  const [errorSB, setErrorSB] = useState();
  const closeError = () => setErrorSB();

  const [loading, setLoading] = useState(false);

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));

  // Render the card social media icons
  const renderSocial = social.map(({link, icon, color}) => (
    <MDBox
      key={color}
      component="a"
      href={addHttpsToURL(link)}
      target="_blank"
      rel="noreferrer"
      fontSize={size.lg}
      color={socialMediaColors[color].main}
      pr={1}
      pl={0.5}
      lineHeight={1}
    >
      {icon}
    </MDBox>
  ));

  //Edit dialog
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState({
    bio: bio,
    title: info.title,
    firstName: info.firstName,
    lastName: info.lastName,
    salutation: info.salutation,
    telephone: info.telephone,
    email: info.email,
    city: info.city,
    state: info.state,
    country: info.country,
  });
  const handleProfileChange = (e) => {
    const {name, value} = e.target;
    setProfile((profile) => ({
      ...profile,
      [name]: value,
    }));
  };

  const [socialMedia, setSocialMedia] = useState({
    facebook: social && social[0]?.link,
    twitter: social && social[1]?.link,
    instagram: social && social[2]?.link,
    linkedin: social && social[3]?.link,
  });

  const handleSocialChange = (e) => {
    const {name, value} = e.target;
    setSocialMedia((socialMedia) => ({
      ...socialMedia,
      [name]: value,
    }));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    // Save the updated profile information here
    profile.social = socialMedia;

    setLoading(true);
    updateProfile(profile)
      .then((result) => {
        // update(false);
        window.location.reload(false);
      })
      .catch((error) => {
        setErrorSB([{title: 'Error saving your profile', message: error}]);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  const renderError = (title, message) => (
    <MDSnackbar color="error" icon="error" title={title} content={message} dateTime="" open={true} onClose={closeError} close={closeError} bgWhite />
  );

  return (
    <Card sx={{height: '100%', boxShadow: !shadow && 'none'}}>
      <MDLoading text="Saving..." open={loading} onClose={() => {}} />
      {errorSB?.length > 0 ? renderError(errorSB[0].title, errorSB[0].message) : null}
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {cardTitle}
        </MDTypography>
        {/* <MDTypography component={Link} to={action.route} variant="body2" color="secondary"> */}
        <MDTypography onClick={handleOpen} variant="body2" color="secondary">
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox mb={2} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {bio}
          </MDTypography>
        </MDBox>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        <MDBox>
          {renderItems}
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              social: &nbsp;
            </MDTypography>
            {renderSocial}
          </MDBox>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            backgroundColor: ({palette}) => (darkMode ? palette.background.default : palette.white.main),
          }}
        >
          Profile Information
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: ({palette}) => (darkMode ? palette.background.default : palette.white.main),
          }}
        >
          <MDInput
            type="text"
            variant="standard"
            // title="Profile Information"
            autoFocus
            margin="dense"
            label="Short bio"
            multiline
            // rows={12}
            value={profile.bio}
            name="bio"
            onChange={handleProfileChange}
            fullWidth
          />
          <InputLabel>Salutation</InputLabel>
          <Select
            type="text"
            variant="standard"
            margin="dense"
            label="Salutation"
            name="salutation"
            onChange={handleProfileChange}
            value={profile.salutation}
            fullWidth
          >
            <MenuItem value="">None</MenuItem>;
            {salutationOptions.map((salutationOption) => (
              <MenuItem key={salutationOption} value={salutationOption}>
                {salutationOption}
              </MenuItem>
            ))}
          </Select>
          <MDInput
            type="text"
            variant="standard"
            margin="dense"
            label="First Name"
            value={profile.firstName}
            name="firstName"
            onChange={handleProfileChange}
            fullWidth
          />
          <MDInput
            type="text"
            variant="standard"
            margin="dense"
            label="Last Name"
            value={profile.lastName}
            name="lastName"
            onChange={handleProfileChange}
            fullWidth
          />
          <MDInput
            type="text"
            variant="standard"
            margin="dense"
            label="Middle Name"
            value={profile.middleName}
            name="middleName"
            onChange={handleProfileChange}
            fullWidth
          />
          <MDInput type="text" variant="standard" margin="dense" label="Title" value={profile.title} name="title" onChange={handleProfileChange} fullWidth />
          <MDInput
            type="text"
            variant="standard"
            margin="dense"
            label="Mobile"
            inputProps={{
              pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
              title: 'Please enter a valid telephone number (XXX-XXX-XXXX)',
            }}
            value={profile.telephone}
            name="telephone"
            onChange={handleProfileChange}
            fullWidth
          />
          <MDInput type="text" variant="standard" margin="dense" label="City" value={profile.city} name="city" onChange={handleProfileChange} fullWidth />
          <InputLabel>State</InputLabel>
          <Select margin="none" label="State" value={profile.state} name="state" onChange={handleProfileChange} fullWidth>
            {stateOptions.map((stateOption) => (
              <MenuItem key={stateOption.abbreviation} value={stateOption.abbreviation}>
                {stateOption.name}
              </MenuItem>
            ))}
          </Select>
          <MDInput
            type="text"
            variant="standard"
            margin="dense"
            label="Country"
            value={profile.country}
            name="country"
            onChange={handleProfileChange}
            fullWidth
          />
          <MDInput
            type="url"
            variant="standard"
            margin="dense"
            label="Facebook public profile URL"
            value={socialMedia?.facebook}
            name="facebook"
            onChange={handleSocialChange}
            fullWidth
          />
          <MDInput
            type="url"
            variant="standard"
            margin="dense"
            label="Twitter public profile URL"
            value={socialMedia?.twitter}
            name="twitter"
            onChange={handleSocialChange}
            fullWidth
          />
          <MDInput
            type="url"
            variant="standard"
            margin="dense"
            label="Instagram public profile URL"
            value={socialMedia?.linkedin}
            name="instagram"
            onChange={handleSocialChange}
            fullWidth
          />
          <MDInput
            type="url"
            variant="standard"
            margin="dense"
            label="LinkedIn public profile"
            value={socialMedia?.linkedin}
            name="linkedin"
            onChange={handleSocialChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: ({palette}) => (darkMode ? palette.background.default : palette.white.main),
          }}
        >
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" onClick={handleClose} fullWidth>
              cancel
            </MDButton>
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="info" onClick={handleSave} fullWidth>
              save
            </MDButton>
          </MDBox>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  cardTitle: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
const salutationOptions = ['Mr.', 'Mrs.', 'Dr.', 'Prof'];
function addHttpsToURL(url) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
}
