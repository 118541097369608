/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// @mui material components
import Card from '@mui/material/Card';
// import Divider from "@mui/material/Divider";
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import MDButton from "components/MDButton";

// Billing page components
import JobEvent from 'layouts/shortList/view/components/JobEvent';

function JobHistory() {
  return (
    <Card sx={{height: '100%'}}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          History
        </MDTypography>
        <MDBox display="flex" alignItems="flex-start">
          <MDBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="inherit" fontSize="small">
              date_range
            </Icon>
          </MDBox>
          <MDTypography variant="button" color="text" fontWeight="regular">
            June 20 - Today
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
            latest
          </MDTypography>
        </MDBox>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} sx={{listStyle: 'none'}}>
          <JobEvent color="info" icon="pending" name="Pending employer response" description="27 March 2020, at 12:30 PM" value="" />
          <JobEvent color="success" icon="send" name="Job application sent by email" description="27 March 2020, at 04:30 AM" value="" />
        </MDBox>
        <MDBox mt={1} mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
            Older
          </MDTypography>
        </MDBox>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} sx={{listStyle: 'none'}}>
          <JobEvent color="success" icon="edit_notes" name="Edited cover letter" description="26 March 2020, at 13:45 PM" value="" />
          <JobEvent color="success" icon="auto_awesome" name="AI generated cover letter" description="26 March 2020, at 12:30 PM" value="$1.99" />
          <JobEvent color="success" icon="bookmark_added" name="Manually added job info from URL" description="26 March 2020, at 08:30 AM" value="" />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default JobHistory;
