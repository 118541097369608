/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState} from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDLoading from 'components/MDLoading';
import MDSnackbar from 'components/MDSnackbar';

// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';
import DataTable from 'tongoComponents/Tables/DataTable';

// Data
import applicationsTableData from 'layouts/system-admin/userAdmin/data/userListData';
import {deleteUser} from '../../../sysadmin';

function UserAdmin() {
  const [appLoading, setAppLoading] = useState(true);
  const [appTable, setAppTable] = useState(null);
  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const handleDataLoaded = (isLoading, tableData) => {
    setAppLoading(isLoading);
    setAppTable(tableData);
  };

  applicationsTableData(handleDataLoaded);

  const handler = async (action, searchId, index) => {
    try {
      switch (action) {
        case 'remove':
          deleteUser(userId);
          setMessageSB([{title: 'Confirmation', message: 'User removed from Tongo AI', color: 'success', icon: 'check'}]);
          break;

        default:
          setMessageSB([{title: 'Error', message: 'User action unknown', color: 'error', icon: 'error'}]);
      }
    } catch (error) {
      setMessageSB([{title: 'Error', message: 'User action error, please try again', color: 'error', icon: 'error'}]);
      console.error('Error:', error.message);
    }
  };

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      autoHide={1000}
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  Tongo AI Users
                </MDTypography>
              </MDBox>
              {/* <MDBox mx={2} mt={3} py={3} px={2} pb={0} pt={0}>
                <MuiLink href="search/new" rel="noreferrer">
                  <MDButton size="medium" color="info">
                    Add a Job Search
                  </MDButton>
                </MuiLink>
              </MDBox> */}
              <MDBox pt={3}>
                {appLoading ? (
                  <MDLoading open={true} />
                ) : appTable?.rows && appTable.rows.length !== 0 ? ( // Check if rows is not null and its length is not 0
                  <DataTable table={appTable} isSorted={true} entriesPerPage={true} showTotalEntries={true} noEndBorder actionHandler={handler} />
                ) : (
                  <MDBox mx={2} mt={3} py={3} px={2} pb={0} pt={0}>
                    <MDTypography variant="body2" color="warning">
                      No users on platform
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
              {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserAdmin;
