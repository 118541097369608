/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useEffect} from 'react';
// react-router-dom components
import {useLocation, NavLink} from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 React example components
import SidenavCollapse from 'tongoComponents/Sidenav/SidenavCollapse';

// Custom styles for the Sidenav
import SidenavRoot from 'tongoComponents/Sidenav/SidenavRoot';
import sidenavLogoLabel from 'tongoComponents/Sidenav/styles/sidenav';
import logoImage from 'assets/images/tongo-logo.png';
// Material Dashboard 2 React context
import {useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav} from 'context';

function Sidenav({color, brand, brandName, routes, ...rest}) {
  const version = process.env.REACT_APP_VERSION;

  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor} = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace('/', '');

  let textColor = 'dark';

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = 'dark';
  } else if (whiteSidenav && darkMode) {
    textColor = 'inherit';
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  //sideNavList is a subset of items from routes.js, items will only be rendered if their key is in this list
  
  const sideNavList = ['applications','search','short-list','resume']
  const renderRoutes = routes.map(({type, name, icon, title, noCollapse, key, href, route}) => {
    let returnValue;

    /*if (type === 'collapse') {
      returnValue = href ? (
        <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{textDecoration: 'none'}}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} noCollapse={noCollapse} />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else */
    if (type === 'collapse') {
      returnValue = href ? (
        <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{textDecoration: 'none'}}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} noCollapse={true} />
        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else 
    
    if (type === 'title') {
      returnValue = (
        <MDTypography key={key} color={textColor} display="block" variant="caption" fontWeight="bold" textTransform="uppercase" pl={3} mt={2} mb={1} ml={1}>
          {title}
        </MDTypography>
      );
    } else if (type === 'divider') {
      returnValue = <Divider key={key} light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)} />;
    }

    return returnValue;
  });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{transparentSidenav, whiteSidenav, miniSidenav, darkMode}}>
      <MDBox pt={3} pb={1} px={2} >
        <MDBox display={{xs: 'block', xl: 'none'}} position="absolute" top={0} right={0} p={1.625} onClick={closeSidenav} sx={{cursor: 'pointer'}}>
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{fontWeight: 'bold'}}>close</Icon>
          </MDTypography>
        </MDBox>
        <Grid container spacing={1}  justifyContent="center" sx={{mt: 1, mb: 2}}>
            <Grid item   alignItems="center" mx={0} >
              
            <MDBox component={NavLink} to="/" display="flex" alignItems="center" >
          {logoImage && <MDBox component="img" width="90%"  src={logoImage} alt="Brand"  />}

        </MDBox>
              
            </Grid>
          </Grid>

      </MDBox>
      <Divider light={(!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)} />
      <List>{renderRoutes}</List>
      <MDBox p={2} mt="auto">
        <MDTypography variant="caption" color="light">
          version:{version}
        </MDTypography>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
