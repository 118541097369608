import {getUserId, getUserResume, fetcher} from 'api';
import localforage from 'localforage';

// Common fetch function for profile tasks
async function profileTask(task, data, filter = null) {
  try {
    const userId = await getUserId();

    switch (task) {
      case 'get':
        const url = `/users/${userId}${filter ? `/?filter=${JSON.stringify(filter)}` : ''}`;
        return await fetcher(url, 'GET');
      case 'update':
        if (typeof data === 'object') {
          return await fetcher(`users/${userId}/`, 'PATCH', data);
        } else {
          throw new Error('data needs to be an object. Do not stringify');
        }
      case 'delete':
        return await fetcher(`users/${data.id}/`, 'DELETE');
      case 'generateResumeKeywords':
        return await fetcher(`users/${userId}/generateResumeKeywords`, 'GET');
      case 'generateDescription':
        console.log("######################generate Description###################userId ",userId)
          return await fetcher(`users/${userId}/jobDescriptions/`, 'GET');
      case 'resendVerificationToken':
        return await fetcher(`users/${userId}/resendVerificationToken`, 'POST');
      case 'uploadResume':
        //not best, using filter as the update param
        const update = filter ? true : false;
        console.log("&&&&&&&&&&&&&&&&&&&users/${userId}/uploadResume?update=${update}",`users/${userId}/uploadResume?update=${update}`)
        return await fetcher(`users/${userId}/uploadResume?update=${update}`, 'POST', data);
    }
  } catch (error) {
    if (error.code === 401) window.location.href = '/sign-in';
    if (error.code === 402) window.location.href = '/resume';
    return Promise.reject(error);
  }
}

// Function for logging in or signing up
export async function loginProfile(query, login = true) {
  const uri = login ? 'users/login' : 'signup';
  const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/${uri}`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  });

  if (!response.ok)
    switch (response.status) {
      case 401:
        throw new Error('Invalid credentials');
      case 403:
        throw new Error('Account is not verified');
      case 404:
        throw new Error('Account does not exist');
      case 409:
        throw new Error('User already exist. Please sign in or recover your password.');
      case 500:
        throw new Error('Error sending new user email verification.');
      default:
        throw new Error('Unknown error');
    }

  const {user, token} = await response.json();
  if (!(user && token)) throw Error('Cannot find account!');
  await loginUser(user, token);

  return true;
}

//Process login information
async function loginUser(user, token) {
  await localforage.setItem('token', token);
  await localforage.setItem('isAuthenticated', true);
  await localforage.setItem('user', user);
  await localforage.setItem('emailVerifed', user?.emailVerified);
  await localforage.setItem('resumeStatus', user?.resumeStatus ?? 'notStarted');

  ///I may not need the following
  // await localforage.setItem('isAdmin', user?.roles?.includes('admin'));
  // await localforage.setItem('isAgencyAdmin', user?.roles?.includes('agencyAdmin'));
  // const emailVerifed = user?.emailVerified;
  return;
}
// Function for resetting password
export async function resetPassword(data) {
  const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/reset-password/init`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok)
    switch (response.status) {
      case 401:
        throw new Error('Invalid credentials');
      case 403:
        throw new Error('Account is not verified');
      case 404:
        throw new Error('Account does not exist');
      case 429:
        throw new Error('Account has reached daily limit for sending password-reset requests');
      case 503:
        throw new Error('Error sending new user email verification.');
      default:
        throw new Error('Unknown error');
    }

  await signoffProfile();
  return true;
}

// Function for finishing password reset
export async function resetPasswordFinish(data) {
  await signoffProfile();
  const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/reset-password/finish`, {
    method: 'PUT',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!response.ok)
    switch (response.status) {
      case 400:
        throw new Error('The reset link has expired. Please try to recover your account again.');
      case 404:
        throw new Error('Account does not exist');
      case 422:
        throw new Error('Password does not match length or complexity requirements');
      default:
        throw new Error('Unknown error');
    }
  const {user, token} = await response.json();
  if (!(user && token)) throw Error('Cannot find account!');
  await loginUser(user, token);

  return true;
}

// Function for verifying an account
export async function verifyAccount(data) {
  const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/verify/${data.verificationToken}`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) throw Error('Cannot verify account');

  const {user, token} = await response.json();
  if (!(user && token)) throw Error('Cannot find account!');
  await loginUser(user, token);

  return true;
}

// Function for signing off the profile
export async function signoffProfile() {
  // await localforage.removeItem('id');
  await localforage.removeItem('token');
  await localforage.removeItem('isAuthenticated');
  // await localforage.removeItem('isAdmin');
  // await localforage.removeItem('isAgencyAdmin');
  await localforage.removeItem('user');

  return null;
}

// Function to check if user is logged in
export async function getLogin() {
  const access = await localforage.getItem('access');
  return access ? true : false;
}

// Function to get profile information
export async function getProfile(filter) {
  return await profileTask('get', null, filter);
}

// Function to generate résumé keywords
export async function generateResumeKeywords(query) {
  return await profileTask('generateResumeKeywords');
}

// Function to resend email verification token
export async function resendVerificationToken() {
  return await profileTask('resendVerificationToken');
}

// Function to update profile information
export async function updateProfile(data) {
  return await profileTask('update', data);
}

// Function to create a profile
export async function createProfile(data) {
  return await loginProfile(data, false);
}

// Function to delete a profile
export async function deleteProfile() {}

// Function to get the main résumé
export async function getMainResume() {
  return await profileTask('get', null, {fields: {mainResume: true, accomodationDefault: true, resumeUpdated: true, resumeKeywords: true}});
}

// Function to get the main résumé
export async function getResume() {
  return await getUserResume();
}

// Function to upload the main résumé
export async function uploadResume(file, update) {
  if (!file) throw new Error('Please select a PDF file');

  // Rename the file by creating a new Blob with the desired name
  const userId = await getUserId();

  const renamedFile = new Blob([file], {type: file.type});
  renamedFile.name = `${userId}.pdf`; // Set the new file name here

  // Create a FormData object and append the renamed file
  const data = new FormData();
  data.append('files', renamedFile, renamedFile.name);

  return await profileTask('uploadResume', data, update);
}
