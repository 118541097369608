/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 React examples
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';
import MasterCard from 'tongoComponents/Cards/MasterCard';
import DefaultInfoCard from 'tongoComponents/Cards/InfoCards/DefaultInfoCard';

// Billing page components
import PaymentMethod from 'layouts/billing/components/PaymentMethod';
import Invoices from 'layouts/billing/components/Invoices';
import BillingInformation from 'layouts/billing/components/BillingInformation';
import Transactions from 'layouts/billing/components/Transactions';

function PaymentTab() {
  return (
    <MDBox mt={8}>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={6}>
                <MasterCard number={4562112245947852} holder="jack peterson" expires="11/22" />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard icon="account_balance" title="Account Balance" description="Available to use throughout the site" value="$20.00" />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultInfoCard icon="paypal" title="Funding Source" description="Last funding a mount Payment" value="$30.00" />
              </Grid>
              <Grid item xs={12}>
                <PaymentMethod />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={4}>
            <Invoices />
          </Grid> */}
        </Grid>
      </MDBox>
      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <BillingInformation />
          </Grid>
          {/* <Grid item xs={12} md={5}>
            <Transactions />
          </Grid> */}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default PaymentTab;
