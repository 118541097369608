/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {getProfile, generateResumeKeywords, updateProfile, uploadResume} from '../../../../profile';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';
import MDBadge from 'components/MDBadge';
import EditIcon from '@mui/icons-material/Edit';
import {useState, useEffect} from 'react';

// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';
import MDInput from 'components/MDInput';

function ResumeTools({keywords, resumeUpdated, reload}) {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const [resumeError, setResumeError] = useState(false);
  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const [resumeDate, setResumeDate] = useState(resumeUpdated);
  const [resumeKeywords, setResumeKeywords] = useState(keywords);

  const [loading, setLoading] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [update, setUpdate] = useState(true);
  const [status, setStatus] = useState('');
  const handleSetUpdate = () => setUpdate(!update);

  useEffect(() => {
    setResumeDate(resumeUpdated);
    setResumeKeywords(keywords);
    if (status !== 'ok') getStatus();
  }, [keywords, resumeUpdated]);

  const handleOnSave = () => {
    setLoading('Saving keywords...');

    updateProfile({resumeKeywords: resumeKeywords})
      .then((result) => {
        setResumeDate(new Date());
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: `Error saving keywords`,
            message: error.message,
          },
        ]);
        reload(false);
      })
      .finally(() => {
        setLoading(false);
        setResumeError(false);
      });
  };
  const handleOnDownload = () => {
    //TODO - implement onDownload
    alert('Feature coming soon!\nThe download feature is coming soon.');

    //   updateProfile({ mainResume: updatedResume, mainResumeUnformatted: updatedResumeUnformatted })
    //     .then((result) => {
    //       setResumeDate(new Date.now());
    //     })
    //     .catch((error) => {
    //       setErrorSB([{ title: 'Error saving résumé', message: error }]);
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //     });
    // }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleClose = () => setOpenDialog(false);

  const handleUpload = () => {
    if (!selectedFile) {
      alert('Please select a file before uploading.');
      return;
    }

    setLoading('Uploading and parsing your résumé...\nThis operation may take some time but you will receive a notification when done.');
    setOpenDialog(false);

    uploadResume(selectedFile, update)
      .then((result) => {
        setSelectedFile(null);
        setResumeDate(Date.now());
        setMessageSB([
          {
            color: 'success',
            icon: 'check',
            title: `Résumé was uploaded successfully!`,
            message: 'Processing will continue in the background, please check status ',
          },
        ]);
        reload(false);
        getStatus();
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: `Error uploading résumé`,
            message: error.message,
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
        setOpenDialog(false);
      });
  };

  const genKeywords = () => {
    setLoading('Generating keywords from your résumé...');

    generateResumeKeywords()
      .then((result) => {
        setResumeKeywords(result);
        setMessageSB([
          {
            color: 'success',
            icon: 'check',
            title: `Success!`,
            message: 'Résumé keywords were created',
          },
        ]);
        reload(false);
      })
      .catch((error) => {
        setMessageSB([
          {
            color: 'error',
            icon: 'error',
            title: `Error generating résumé keyword`,
            message: error.message,
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      autoHide={5000}
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );

  const renderDate = (date) => {
    return date
      ? new Date(date).toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      : 'No update yet';
  };

  const renderResumeStatus = () => {
    switch (status) {
      case 'notStarted':
        return <MDBadge badgeContent="No résumé" color="error" variant="gradient" size="md" />;
      case 'uploaded':
        return <MDBadge badgeContent="résumé uploaded" color="info" variant="gradient" size="md" />;
      case 'inProgress':
        return (
          <>
            <MDBadge badgeContent="résumé parsing in progress" color="info" variant="gradient" size="md" />
            <CircularProgress color="info" />
          </>
        );
      case 'noResults':
        return <MDBadge badgeContent="cannot process résumé" color="secondary" variant="gradient" size="md" />;
      case 'failed':
        return <MDBadge badgeContent="parsing failed" color="warning" variant="gradient" size="md" />;
      case 'ok':
        return <MDBadge badgeContent="Résumé OK" color="success" variant="gradient" size="lg" />;
      default:
      case 'pending':
        return (
          <>
            <MDBadge badgeContent="Résumé parsing pending" color="info" variant="gradient" size="xs" />
            <CircularProgress size={1} color="info" />
          </>
        );
    }
  };

  const getStatus = () => {
    const fetchStatus = () => {
      getProfile({fields: {resumeStatus: true}})
        .then((s) => {
          setStatus(s.resumeStatus);
          if (s.resumeStatus === 'ok') {
            clearInterval(statusCheckInterval); // Clear the interval when status is 'ok'
            reload(true);
          }
        })
        .catch(() => {});
    };

    // Make the initial call to fetchStatus
    fetchStatus();

    let statusCheckInterval;

    // Set up the interval if the initial status is not 'ok'
    if (status !== 'ok') {
      statusCheckInterval = setInterval(fetchStatus, 5000); // Retry every 5 seconds
    }
  };

  return (
    <>
      {' '}
      {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
      <MDLoading text={loading === false ? '' : loading} open={Boolean(loading)} />
      {openDialog ? (
        <Dialog open={openDialog} onClose={handleClose} aria-labelledby="Alert confirmation" aria-describedby="Upload confirmation">
          <DialogTitle>Upload résumé</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Select a PDF document exported from LinkedIn. The document must have a Contact, Experience and Education sections.
              <br /> Once uploaded, we will convert the document into a Tongo résumé.
            </DialogContentText>
            <MDBox mt={4}>
              <MDButton variant="gradient" component="label" color={darkMode ? 'dark' : 'info'} startIcon={<Icon>attach_file</Icon>}>
                <input
                  type="file"
                  accept=".pdf" // Define the allowed file types
                  onChange={handleFileChange}
                  style={{display: 'none'}}
                  id="fileInput"
                />
                Choose résumé file
              </MDButton>
              <MDTypography variant="body" color="text" ml={5}>
                {selectedFile ? selectedFile.name : 'No file selected'}
              </MDTypography>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch color="info" name="update" checked={update} onChange={handleSetUpdate} />
                <MDTypography variant="button" fontWeight="regular" color="text" onClick={handleSetUpdate} sx={{cursor: 'pointer', userSelect: 'none', ml: -1}}>
                  &nbsp;&nbsp;Update my account with info from my résumé
                </MDTypography>
              </MDBox>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton variant="gradient" color="info" onClick={handleUpload} disabled={!Boolean(selectedFile)}>
              Upload
            </MDButton>
            <MDButton variant="gradient" color="success" onClick={handleClose} autoFocus>
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      ) : null}
      {Boolean(resumeError) && (
        <Grid item xs={12} sm={6} lg={3}>
          <MDButton variant="gradient" color="error" fullWidth>
            You must create or upload a LinkedIn PDF résumé before using Tongo AI
          </MDButton>
        </Grid>
      )}
      <MDBox mb={0}>
        <Grid>
          <Card id="job-info">
            <MDBox pt={0} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                Résumé Tools
              </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={darkMode ? 'transparent' : 'grey-100'}
                  borderRadius="lg"
                  p={3}
                  mb={1}
                  mt={2}
                >
                  <MDBox width="100%" display="flex">
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{xs: 'flex-start', sm: 'center'}}
                      flexDirection={{xs: 'column', sm: 'row'}}
                      mb={0}
                    >
                      <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                        {name}
                      </MDTypography>
                      <MDBox display="fix" alignItems="center" mt={{xs: 2, sm: 0}} ml={{xs: -1.5, sm: 0}}>
                        <MDBox mr={1}>
                          <MDButton variant="text" color="info">
                            <Icon>account_tree</Icon>&nbsp;résumé wizard
                          </MDButton>
                          <MDButton variant="text" color="info" onClick={() => setOpenDialog(true)}>
                            <Icon>upload</Icon>&nbsp;upload résumé
                          </MDButton>
                          <MDButton variant="text" color="info">
                            <Icon>picture_as_pdf</Icon>&nbsp;download Tongo&apos;s résumé
                          </MDButton>
                          <MDButton variant="text" color="info">
                            <Icon>picture_as_pdf</Icon>&nbsp;download uploaded résumé
                          </MDButton>
                          {!resumeKeywords?.length ? (
                            <MDButton
                              variant="text"
                              color="info"
                              size="small"
                              onClick={() => {
                                genKeywords();
                              }}
                            >
                              <Icon>search</Icon>
                              {' Update job search keywords'}
                            </MDButton>
                          ) : null}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>

                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor="transparent"
                  borderRadius="lg"
                  p={0.1}
                  mb={0.1}
                  mt={0.1}
                >
                  <MDBox mt={0} width="100%" display="flex" flexDirection="column">
                    <Autocomplete
                      multiple
                      id="tags-standard"
                      size="medium"
                      options={resumeKeywords ?? []}
                      value={resumeKeywords ?? []} // Set the defaultValue to the array of keywords
                      renderInput={(params) => (
                        <MDInput {...params} variant="standard" label="Résumé / Job search keywords" placeholder="Keywords" fullWidth onBlur={handleOnSave} />
                      )}
                      limitTags={10}
                      freeSolo
                      onChange={(event, newValue) => setResumeKeywords(newValue)}
                    />
                  </MDBox>
                </MDBox>
                <MDBox mt={3} lineHeight={1}>
                  <MDTypography variant="caption" color="text">
                    Résumé last updated:&nbsp;&nbsp;&nbsp;
                    <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                      {renderDate(resumeDate)}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
                <MDBox>{status ? renderResumeStatus(status) : null}</MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
}

export default ResumeTools;

ResumeTools.propTypes = {
  keywords: PropTypes.array,
  resumeUpdated: PropTypes.string,
  reload: PropTypes.func,
};
