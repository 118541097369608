/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// react-router-dom components
import {useParams, useNavigate} from 'react-router-dom';
import {createJob, updateJob, getJob} from '../../../jobs';
import {getResume} from '../../../profile';
import {useState, useRef, useEffect} from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';
import Resume from 'components/MDCopyable';

// Material Dashboard 2 React base styles

// Material Dashboard 2 React example components
import DashboardLayout from 'tongoComponents/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'tongoComponents/Navbars/DashboardNavbar';
import Footer from 'tongoComponents/Footer';

// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';

function ApplyToJob() {
  const navigate = useNavigate();
  const queryParams = useParams();
  const [job, setJob] = useState({
    jobType: 'full-time',
    coverLetterStatus: 'pending',
    name: '',
    jobPostingUrl: '',
    jobLocation: {
      city: '',
      state: '',
    },
    companyName: '',
    companyWebsite: '',
    jobSalary: '',
    jobCategory: '',
    jobType: 'full-time',
    tags: '',
    coverLetterStatus: 'pending',
  });

  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState();

  const handleToggle = () => {
    setLoading(!loading);
  };

  const editorRef = useRef(null);
  //editorRef.current.mode.set('readonly');
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const [messageSB, setMessageSB] = useState([]);
  const closeMessage = () => setMessageSB([]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      let successNavigate;
      if (job.id) {
        // job.id = job.id;
        //update returns true
        await updateJob(job);
        successNavigate = `/short-list/${job.id}`;
      } else {
        let newJob = await createJob(job);
        successNavigate = `/short-list/${newJob.id}`;
      }

      setMessageSB([
        {
          color: 'success',
          icon: 'check',
          title: `Job was ${job.id ? 'updated' : 'created'}!`,
          message: 'Redirecting to applications list...',
        },
      ]);

      setTimeout(() => {
        navigate(successNavigate);
      }, 2000);
    } catch (error) {
      if (error.status === 401) {
        console.log("401 no authenticatedzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz")
        await profile.signoffProfile();
        navigate('/sign-in');
      }
      setMessageSB([
        {
          color: 'error',
          icon: 'error',
          title: `Error ${job.id ? 'updating' : 'creating'} job!`,
          message: 'Please review your info and try again!',
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResume().then((arrResume) => {
      setResume(arrResume);
    });

    if (queryParams?.jobId && !job?.id)
      getJob(queryParams.jobId)
        .then((job) => {
          setJob(job);
        })
        .catch((error) => {
          // if (error.code === 401) {
          // Perform the redirect to the login screen
          // window.location.href = '/sign-in';
          // } else {
          console.error(error);
          // Handle other errors
          // }
        })
        .finally(() => {
          setLoading(false);
        });
  }, []);

  const renderMsg = (color, icon, title, message) => (
    <MDSnackbar
      color={color}
      icon={icon}
      title={title}
      content={message}
      dateTime=""
      open={Boolean(messageSB)}
      onClose={closeMessage}
      close={closeMessage}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {messageSB.length ? renderMsg(messageSB[0].color, messageSB[0].icon, messageSB[0].title, messageSB[0].message) : null}
      <MDLoading text="Retrieving job data..." open={loading} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Applying to:
                  <br />
                </MDTypography>
                <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                  {job?.jobTitle}
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                  Tongo can apply on your behalf if this job can be applied to using an email address.
                  <br />
                  You can manually track your application by entering the info below.
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2}>
                <MDTypography variant="h5" fontWeight="medium">
                  Your résumé
                </MDTypography>
                <MDTypography variant="text" fontWeight="medium">
                  Quickly copy and paste your résumé to the job application.
                </MDTypography>
              </MDBox>
              <MDBox pt={1} pb={2} px={2}>
                <Grid item xs={6}>
                  <Resume resumeData={resume} />
                </Grid>
                <MDBox display="flex" alignItems="left" mt={5}>
                  <MDBox mr={5}>
                    <MDButton type="submit" variant="gradient" color="info" mr={2}>
                      Apply by Email
                    </MDButton>
                  </MDBox>
                  <MDBox>
                    <MDButton onClick={() => navigate(-1)} variant="gradient" color="info" mr={2}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ApplyToJob;
