/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/
import { generateCoverLetter, updateJob } from '../../../../../jobs';
import DescriptionIcon from '@mui/icons-material/Description';
import PropTypes from 'prop-types';
import { useState, useRef, useEffect, arrowRef } from 'react';
import ListItemText from '@mui/material/ListItemText';
import { Editor } from '@tinymce/tinymce-react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// @mui material components
import Popper from '@mui/material/Popper';
import Popover from '@mui/material/Popover';
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import styled from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
// Material Dashboard 2 React components+
import { saveAs } from "file-saver";
import { Packer } from "docx";
import MenuIcon from '@mui/icons-material/Menu';
import { CLWordExport } from "../CLWordExport";
import { WordExport } from 'layouts/resume/components/WordExport';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDSnackbar from 'components/MDSnackbar';
import MDLoading from 'components/MDLoading';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DownloadIcon from '@mui/icons-material/Download';
import NotesIcon from '@mui/icons-material/Notes';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import { spacing } from '@mui/system';
// Material Dashboard 2 React context
import { useMaterialUIController } from 'context';
import Menu from '@mui/material/Menu';
import { withStyles } from "@mui/material/styles";
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import editorImg from 'assets/images/editor.png';



import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { BorderColor } from '@mui/icons-material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CoverLetter({ jobId, coverLetterStatus, coverLetter, requestedAccomodation, requestingAccommodation, resume }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [errorSB, setErrorSB] = useState();
  const closeError = () => setErrorSB();

  const [fetched, setFetched] = useState(false);

  const editorRef = useRef(null);
  //editorRef.current.mode.set('readonly');

  const [editing, setEditing] = useState(false);
  const [cl, setCl] = useState(coverLetter);

  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Loading');
  const [anchorEl, setAnchorEl] = useState(null);


  const [anchorElEditor, setAnchorElEditor] = useState(null);
  const [open, setOpen] = useState(true);


  const popRef = useRef(null);

  const id = open ? "simple-popover" : undefined;



  const handleToggle = () => {
    setLoading(!loading);
  };

  const handleOnEdit = () => {
    setEditing(true);
  };

  const handleEditorChange = (content, editor) => {
    setCl({ editorContent: content });
  };

  if (coverLetterStatus === 'generating') {
    setLoading(true);
    setLoadingMsg('Creating cover letter...');
  }

  const handleOnSave = () => {
    const newCoverLetter = editorRef.current.getContent();
    setEditing(false);
    if (newCoverLetter !== cl) {
      setLoading(true);
      setLoadingMsg('Saving...');

      if (editorRef.current) {
        updateJob({ id: jobId, coverLetter: newCoverLetter, coverLetterStatus: 'edited' })
          .then((result) => {
            setCl(newCoverLetter);
          })
          .catch((error) => {
            setErrorSB([{ title: 'Error saving cover letter', message: error }]);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };


  const noGutter = 0;

  const renderError = (title, message) => (
    <MDSnackbar color="error" icon="error" title={title} content={message} dateTime="" open={true} onClose={closeError} close={closeError} bgWhite />
  );
  function generate() {

    //let skills = resume?.resumeParsed[3].customFields//custom fields for now
    const exp = new CLWordExport();
    console.log(coverLetter)
    console.log(cl)
    console.log("cover letter is: ", cl.editorContent)
    const cLArr = []
    const cLArrSplit = String(cl.editorContent).split("\n")



    console.log("cLArrSplit is: ", cLArrSplit)


    for (let i = 0; i < cLArrSplit.length; i++) {
      cLArr.push(cLArrSplit[i].replace("<p>", "").replace("</p>", "").replace("&nbsp;", ""))
      console.log("cLArr is: ", cLArr)
    }

    const doc = exp.create(cLArr);



    Packer.toBlob(doc).then(blob => {
      console.log(blob);
      saveAs(blob, "CoverLetter.docx");
      console.log("Cover Letter created successfully");
    });
    const expRes = new WordExport();

    console.log("####################### resume: ", resume)
    const docRes = expRes.create(resume);

    Packer.toBlob(docRes).then(blob => {
      console.log(blob);
      saveAs(blob, "Resume.docx");
      console.log("Resume created successfully");
    });
  }
  const style = {

    primaryTypographyProps: {
      fontSize: 14
    }
  };
  const divRef = useRef();


  function wordExport() {

    //let skills = resume?.resumeParsed[3].customFields//custom fields for now
    const exp = new CLWordExport();
    console.log(coverLetter)
    console.log(cl)
    console.log("cover letter is: ", cl.editorContent)
    const cLArr = []
    const cLArrSplit = String(cl.editorContent).split("\n")



    console.log("cLArrSplit is: ", cLArrSplit)


    for (let i = 0; i < cLArrSplit.length; i++) {
      cLArr.push(cLArrSplit[i].replace("<p>", "").replace("</p>", "").replace("&nbsp;", ""))
      console.log("cLArr is: ", cLArr)
    }

    const doc = exp.create(cLArr);



    Packer.toBlob(doc).then(blob => {
      console.log(blob);
      saveAs(blob, "CoverLetter.docx");
      console.log("Cover Letter created successfully");
    });
    const expRes = new WordExport();

    console.log("####################### resume: ", resume)
    const docRes = expRes.create(resume);

    Packer.toBlob(docRes).then(blob => {
      console.log(blob);
      saveAs(blob, "Resume.docx");
      console.log("Resume created successfully");
    });
  }




  const handleGenerateCL = () => {
    setLoadingMsg('Creating cover letter...');
    setLoading(true);
    if (open) {
      setOpen(!open)
    }

    generateCoverLetter(jobId)
      .then((cl) => {
        setCl(cl.editorContent);
        editorRef.current.execCommand('mceSetContent', false, cl.coverLetter);
        coverLetterStatus = 'generated';
      })
      .catch((error) => {
        switch (error.code) {
          case 402:
            coverLetterStatus = 'Needs payment';
            setErrorSB([{ title: 'Error creating cover letter', message: 'Please add a payment method or add credit to your account' }]);
            break;
          case 422:
            coverLetterStatus = 'Missing résumé in profile';
            setErrorSB([{ title: 'Error creating cover letter', message: 'Please upload a résumé before creating a cover letter' }]);
            break;
          case 500:
            coverLetterStatus = 'Job description too long. Edit unneeded job description or info.';
            setErrorSB([{ title: 'Error creating cover letter', message: 'Please reduce the size of the job description' }]);
            break;
          default:
            coverLetterStatus = 'Error creating cover letter';
            setErrorSB([{ title: 'Error creating cover letter', message: error.message }]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <Card id="coverletter" sx={{ paddingX: "20px", minHeight: "600px", width: "100%", paddingTop: "3%" }} >


      <MDLoading text={loadingMsg} open={loading} onClose={() => { }} />
      {errorSB?.length > 0 ? renderError(errorSB[0].title, errorSB[0].message) : null}



      <Box ref={popRef} >

        {console.log('anchor el: ', popRef)}
        <Popper
          container={document.getElementsByClassName("tox-edit-area__iframe")}
          disablePortal={true}
          open={open}
          anchorEl={popRef}
          placement={"bottom"}
          sx={{ transform: "translate(10%,90%)", position: "absolute!important", zIndex: 1200 }}

        >
          <Stack spacing={3} px={4} sx={{ marginX: "auto", minWidth: "600px", minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(72, 113, 247,0.1)" }}>
            <Typography variant="h5" component="div" sx={{ fontSize: 20 }} color="text.secondary" >
              Would you like us to save you hours of writing?

            </Typography>
            <Typography variant="h5" component="div" sx={{ fontSize: 16 }} color="text.secondary" >
              Have our Resume Consultant write a beautiful custom cover letter in seconds for only $1.99
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <Tooltip title="This feature requires payment" placement="left-start">
                <Button aria-describedby={id} variant="contained" endIcon={<ThumbUpIcon />} sx={{ color: "#ffff" }} onClick={handleGenerateCL}>
                  <Typography component="div" sx={{ textTransform: 'capitalize', fontSize: 14 }} color="#ffff" >

                    Write me a cover letter!  </Typography>
                </Button>


              </Tooltip>


              <Button aria-describedby={id} variant="outlined" onClick={() => setOpen(!open)}>
                <Typography variant="h5" component="div" sx={{ textTransform: 'capitalize', fontSize: 12 }} color="#text.secondary" >
                  No thanks
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Popper>
      </Box>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={coverLetter}
        remove_linebreaks={false}
        init={{
          promotion: false,
          height: 500,

          toolbar:'undo redo | blocks | ' +
      'bold italic  | alignleft aligncenter ' +
      'alignright alignjustify customInsertButton| bullist numlist outdent indent forecolor',
          selector: '#tinymce',
          menu: {
            file: { title: 'File', items: 'newdocument restoredraft | preview | importword exportpdf exportword | print | deleteallconversations' },
            edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
            view: { title: 'View', items: 'code revisionhistory | visualaid visualchars visualblocks | spellchecker | preview fullscreen | showcomments' },
            insert: { title: 'Insert', items: 'image link media addcomment pageembed codesample inserttable | math | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime' },
            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | styles blocks fontfamily fontsize align lineheight | forecolor backcolor | language | removeformat' },
            tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | a11ycheck code wordcount' },
            table: { title: 'Table', items: 'inserttable | cell row column | advtablesort | tableprops deletetable' },
            help: { title: 'Help', items: 'help' }
          },
          branding: false,

    


          setup: (editor) => {
            editor.ui.registry.addButton('customInsertButton', {
              text: 'Write My Coverletter',
              style: "",
              onAction:  () => { <MenuItem onClick={handleGenerateCL}><AutoFixHighIcon fontSize="medium" />&nbsp;Create cover letter</MenuItem>},
            }),

              editor.ui.registry.addMenuItem('exportpdf', {
                icon: 'save',
                text: 'Export as PDF',
                cmd: '',
                context: 'file',
                disabled: false,
                onAction: () => alert('Button clicked!')
              }),

              editor.ui.registry.addMenuItem('exportword', {
                icon: 'save',
                text: 'Export as Word doc',
                cmd: 'mceSave',
                context: 'file',
                disabled: false,
                onAction:  () =>{wordExport()}
              })
          },
          plugins: [
      'advlist',
      'autolink',
      'lists',
      'link',
      'image',
      'charmap',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'fullscreen',
      'wordcount',
      ],
      editor_css:'tox-tbtn--select. {color:#ffffff; background-Color: #015393!important;}',
      content_style: 'body {font - family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
      onFocusOut={handleOnSave}
      onFocusIn={handleOnEdit}
      onEditorChange={handleEditorChange}
                />









    </Card >
  );
}
// Setting default values for the props of Bill
CoverLetter.defaultProps = {
  coverLetter: '',
  requestedAccomodation: '',
  requestingAccommodation: false,
};

// Typechecking props of the JobInfo
CoverLetter.propTypes = {
  jobId: PropTypes.string.isRequired,
  coverLetterStatus: PropTypes.string.isRequired,
  coverLetter: PropTypes.string,
  requestedAccomodation: PropTypes.string,
  requestingAccommodation: PropTypes.bool,
  resume: PropTypes.object,
};

export default CoverLetter;
