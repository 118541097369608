/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

// @mui icons
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
 
// Material Dashboard 2 React example components
import ProfileInfoCard from 'tongoComponents/Cards/InfoCards/ProfileInfoCard';
import ProfilesList from 'tongoComponents/Lists/ProfilesList';

// Overview page components
import PlatformSettings from 'layouts/account/components/PlatformSettings';

function SettingsTab(profile) {
  const socialArray = [];
  profile?.social &&
    Object.entries(profile.social).map(([key, value]) => {
      switch (key) {
        case 'facebook':
          socialArray.push({link: value, icon: <FacebookIcon />, color: 'facebook'});
          break;
        case 'twitter':
          socialArray.push({
            link: value,
            icon: <TwitterIcon />,
            color: 'twitter',
          });
          break;
        case 'instagram':
          socialArray.push({
            link: value,
            icon: <InstagramIcon />,
            color: 'instagram',
          });
          break;
        case 'linkedin':
          socialArray.push({
            link: value,
            icon: <LinkedInIcon />,
            color: 'linkedin',
          });
          break;
      }
    });

  return (
    <MDBox mt={5} mb={3}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <PlatformSettings {...profile?.settings} />
        </Grid>
        <Grid item xs={12} md={6} xl={4} sx={{display: 'flex'}}>
          <Divider orientation="vertical" sx={{ml: -2, mr: 1}} />
          <ProfileInfoCard
            cardTitle="your information"
            bio={profile?.bio ? profile.bio : 'No short bio'}
            info={{
              salutation: profile?.salutation ?? '',
              firstName: profile?.firstName ?? '',
              lastName: profile?.lastName ?? '',
              middleName: profile?.middleName ?? '',
              title: profile?.title ?? '',
              telephone: profile?.telephone ?? '',
              email: profile?.email ?? '',
              city: profile?.city ?? '',
              state: profile?.state ?? '',
              country: profile?.country ?? '',
            }}
            // social={profile?.social && `${profile.social}`}

            social={socialArray}
            action={{type: 'internal', route: '', tooltip: 'Edit Profile'}}
            shadow={false}
          />
          <Divider orientation="vertical" sx={{ml: -2, mr: 1}} />;
        </Grid>
        <Grid item xs={12} xl={4}>
          <ProfilesList
            title="special accommodations and mentions"
            description="Add any special training, physical or health concerns, religious restrictions, etc... to consider while creating a cover letter, résumé, or job search criteria."
            edit={{type: 'internal', route: '', tooltip: 'Add'}}
            profiles={profile?.accommodations ?? []}
            shadow={false}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}
// Setting default props for the Header
SettingsTab.defaultProps = {
  profile: {},
};

// Typechecking props for the Header
SettingsTab.propTypes = {
  profile: PropTypes.object,
};

export default SettingsTab;
