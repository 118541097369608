import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close'
import {Tooltip, Typography} from '@mui/material/Typography'
import { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

/*
export default function PositionedTooltips() {
  return (
    <Box sx={{ width: 500 }}>
      <Grid container justifyContent="center">
        <Grid item>
          <Tooltip title="Add" placement="bottom">
            <Button>bottom</Button>
          </Tooltip>

        </Grid>
      </Grid>
    </Box>
  );
}







function  AnimatedToolTip(tTTitle, message) {
   const [open, setOpen] = useState(true)
   
   const handleClose = e => {
    if (open) {
      setOpen(false)
    }
    return null
  }

  return(
    <Tooltip
        open={open}
        title={
          <>
            <CloseIcon sx={{ float: 'right' }} onClick={e => handleClose(e)}></CloseIcon>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              {tTTitle}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 14 }}>
              {message}
            </Typography>
          </>
        }
      >
      </Tooltip>
  )
    }
    export default AnimatedToolTip;
    AnimatedToolTip.propTypes = {
        title: PropTypes.string,
        message: PropTypes.string,
    
      };
      */
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import HelpIcon from '@mui/icons-material/Help';
export default function AnimatedToolTip( {toolTipGif}) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };



  const handleClickAway = () => {
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  return (
    <div>
<ClickAwayListener onClickAway={handleClickAway}>
      <Box ml={2} pt={2}><HelpIcon onMouseEnter={handleClick}/> {/*onMouseLeave={handleClick}/>  */}
   </Box>
   </ClickAwayListener>
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
       
            <MDBox component="img" src={toolTipGif} alt="linkedin tooltip" mr={2} />
            </Box>
          </Fade>
        )}
      </Popper>
      
    </div>
  );
}