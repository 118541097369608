import {fetcher, getUserId} from 'api';

async function searchTask(task, data, filter = null) {
  try {
    const userId = await getUserId();

    switch (task) {
      case 'create':
        return await fetcher(`searches/`, 'POST', data);
      case 'get':
        return await fetcher(`/${data?.id ? `searches/${data.id}/` : `searches`}${filter ? `?filter=${filter}` : ''}`, 'GET');
      case 'update':
        if (typeof data === 'object') return await fetcher(`searches/${data.id}/`, 'PATCH', data);
        else throw new Error('update object is invalid');
      case 'delete':
        return await fetcher(`searches/${data.id}/`, 'DELETE');
      case 'archive':
        return await fetcher(`searches/${data.id}/`, 'PATCH', {archived: true});
      case 'refresh':
        return await fetcher(`searches/${data.id}/search`, 'GET');
      case 'shortListJob':
        return await fetcher(`searches/${data.id}/search/shortListJob/${data.index}`, 'GET');
      case 'deleteJob':
        return await fetcher(`searches/${data.id}/search/deleteJob/${data.index}`, 'DELETE');
    }
  } catch (error) {
    if (error.code === 401) window.location.href = '/sign-in';
    return Promise.reject(error);
  }
}

export async function createSearch(data) {
  if (!data) return;
  return await searchTask('create', data);
}

export async function getSearch(id, filter) {
  try {
    console.log("getting search result")
    const result = await searchTask('get', id ? {id: id} : null, filter);
    console.log("search result is", result)
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateSearch(data) {
  if (!data?.id) return;
  return await searchTask('update', data);
}

export async function refreshSearch(id) {
  if (!id) throw new Error('id is required');
  return await searchTask('refresh', {id: id});
}

export async function deleteSearch(id) {
  if (!id) throw new Error('id is required');
  return await searchTask('delete', {id: id});
}

export async function archiveSearch(id) {
  if (!id) throw new Error('id is required');
  return await searchTask('archive', {id: id});
}

export async function deleteSearchResult(id, index) {
  if (typeof id !== 'string') {
    throw new Error('Invalid id');
  }
  if (typeof index !== 'number' || index < 0) {
    throw new Error('Invalid index');
  }
  return await searchTask('deleteJob', {id: id, index: index});
}

export async function shortListSearchResult(id, index) {
  console.log("id is ", id )
  if (typeof id !== 'string') {
    throw new Error('Invalid id');
  }
  if (typeof index !== 'number' || index < 0) {
    throw new Error('Invalid index');
  }
  return await searchTask('shortListJob', {id: id, index: index});
}
