/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Tongo.AI
=========================================================

* Copyright 2023 Tongo.AI
=========================================================
*/

import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';
import ActionMenu from 'tongoComponents/ActionMenu';

// Images
// import team2 from 'assets/images/small-logos/indeed.svg';
// import team1 from 'assets/images/team-1.jpg';
// import team2 from 'assets/images/team-2.jpg';
// import team3 from 'assets/images/team-3.jpg';
// import team4 from 'assets/images/team-4.jpg';

import {getUsers, deleteUser, archiveUser, passwordReset} from '../../../../sysadmin';

export default function data(callback) {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);
  const [usersCache, setUsersCache] = useState([]);
  const [fetched, setFetched] = useState(false);

  const navigate = useNavigate();

  const tasks = [
    {
      label: 'Manage',
      icon: 'edit_note',
      action: (id) => {
        navigate(`/admin/users/${id}/edit`);
      },
      confirmation: false,
      confirmationMessage: '',
    },
    {
      label: 'Archive',
      icon: 'archive',
      action: archiveUser,
      confirmation: true,
      confirmationMessage: 'Are you sure you want to archive this user? This action can be undone by changing the user filter.',
    },
    {
      label: 'Send password reset email',
      icon: 'mail',
      action: passwordReset,
      confirmation: true,
      confirmationMessage: 'Are you sure you want to send a reset password for this user?',
    },
    {
      label: 'Delete',
      icon: 'delete',
      action: deleteUser,
      confirmation: true,
      confirmationMessage: 'Are you sure you want to delete this user? This action cannot be undone.',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!fetched) {
          const data = await getUsers(null); // '{"where":{"archived":{"neq":true}, "deleted":{"neq":true}}}');
          setUsersCache(data);
          setFetched(true);
        }
        const columns = [
          {
            Header: 'Email',
            accessor: 'name',
            width: '30%',
            align: 'left',
          },
          {Header: 'status', accessor: 'status', align: 'center'},
          {Header: 'agency', accessor: 'agency', align: 'left'},
          {Header: 'roles', accessor: 'roles', align: 'center'},
          {Header: 'location', accessor: 'location', align: 'center'},
          {Header: 'action', accessor: 'action', align: 'center'},
        ];
        const rows = usersCache.map((user) => ({
          id: user.id,
          name: <User avatar={user?.avatar} lastName={user?.lastName} firstName={user?.firstName} email={user?.email} />,
          status: <MDBox ml={-1}>{renderStatus(user?.emailVerified)}</MDBox>,
          agency: (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
              <MDBox ml={2} lineHeight={1}>
                {/* <MDBox ml={-1}>{user?.count || 0}</MDBox> */}
                <MDTypography display="block" variant="caption">
                  {user?.agencyId ? user.agencyId : 'Individual'}
                </MDTypography>
              </MDBox>
            </MDBox>
          ),
          roles: <MDBox ml={-1}>{renderRole(user?.roles)}</MDBox>,
          location: <Location city={user?.city} state={user?.state} />,
          action: <ActionMenu id={user.id} onActionCompleted={() => setTimeout(() => setFetched(false), )} tasks={tasks} />,
        }));
        setTable({columns, rows});
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [fetched]);

  const User = ({avatar, lastName, firstName, email}) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar
        src={`https://www.gravatar.com/avatar/${avatar ? avatar : '3b3be63a4c2a439b013787725dfce802'}?d=identicon`}
        alt="profile-image"
        size="xs"
        shadow="xs"
      />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {email ?? ''}
        </MDTypography>
        <MDTypography display="block" variant="caption">
          {firstName ?? ''} {lastName ?? ''}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Location = ({city, state}) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {city ?? ''}, {state ?? ''}
      </MDTypography>
    </MDBox>
  );

  const renderDate = (date) => {
    const now = new Date();
    const dateObj = new Date(date);
    const timeDiff = now - dateObj;
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (!date) return 'No user performed yet';
    if (minutesDiff < 15) {
      return 'a few minutes ago';
    } else if (hoursDiff <= 1.5) {
      return 'an hour or so ago';
    } else if (hoursDiff <= 24) {
      return 'today';
    } else if (hoursDiff <= 48) {
      return 'yesterday';
    } else if (daysDiff <= 3) {
      return 'a couple of days ago';
    } else if (daysDiff <= 7) {
      return `ast ${dateObj.toLocaleDateString('en-US', {weekday: 'long'})}`;
    } else if (now.getFullYear() === dateObj.getFullYear()) {
      return dateObj.toLocaleDateString('en-US', {month: 'short', day: 'numeric'});
    } else {
      return dateObj.toLocaleDateString('en-US');
    }
  };

  const renderRole = (roles) => {
    if (roles?.includes('admin')) return <MDBadge badgeContent="Admin" color="info" variant="gradient" size="sm" />;
    if (roles?.includes('agencyAdmin')) return <MDBadge badgeContent="Agency Admin" color="info" variant="gradient" size="sm" />;
    if (roles?.includes('user')) return <MDBadge badgeContent="User" color="info" variant="gradient" size="sm" />;
    return <MDBadge badgeContent="No role" color="error" variant="gradient" size="sm" />;
  };

  const renderStatus = (status) => {
    return status ? (
      <MDBadge badgeContent="verified" color="success" variant="gradient" size="xs" />
    ) : (
      <MDBadge badgeContent="email not verified" color="error" variant="gradient" size="xs" />
    );
  };

  useEffect(() => {
    if (callback) {
      callback(loading, table);
    }
  }, [callback, loading, table]);
  return {loading, table};
}
